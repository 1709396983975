// const BASE_URL = "http://3.37.242.5:8080/";  // 예전 배포 서버

// const BASE_URL = "http://52.79.134.205:8080/gravity/";  // 배포 서버
// const BASE_URL = "http://118.38.38.162:8080/gravity2/";  // 테스트 서버
// const BASE_URL = "http://183.103.166.187/gravity2/"; // 테스트 서버 2

// export const BASE_URL = "http://183.103.166.187/gravity2/";
export const BASE_URL = "http://13.209.243.244:8080/gravity2/";
// export const BASE_URL = "http://59.20.45.201:8080/gravity2/";
// export const BASE_URL = "http://3.37.242.5:8080/gravity/"

// 모바일 개발 서버
// export const M_URL = "http://183.103.166.187/gravity2/";
export const M_URL = "http://13.209.243.244:8080/gravity2/";
// export const M_URL = "http://59.20.45.201:8080/gravity2/";
// export const M_URL = "http://3.37.242.5:8080/gravity/"

// 개발 이미지 경로
export const M_IMG = "http://13.209.243.244:8080/resource/gravity/";
// export const M_IMG = "http://59.20.45.201:8080/resimg/gravity/";
// export const M_IMG = "http://3.37.242.5:8080/resimg/gravity/"
// 운영 이미지 경로
// const M_IMG = "http://52.79.134.205/resource/gravity/"

// 이니시스 API KEY 값
// aHNVbUlJVFpBZEE1bmJCMUs5U0RKUT09

// ID : MOIAPP9690
// PW : !rICK5500

export default {
  // ID : gravitystudio
  // PW : gravity3579

  // ***************** 서버 호출
  // SERVER_URL: BASE_URL,
  SERVER_URL: M_URL,
  // API_URL: BASE_URL + 'gravity/',
  // API_URL: BASE_URL + 'gravity2/',
  // API_URL: BASE_URL,
  API_URL: M_URL,

  // IMG_URL: BASE_URL + 'resource/gravity/profile/',
  // IMG_URL: M_IMG + 'resource/gravity/profile/',
  // IMG_URL: M_IMG + 'resimg/gravity/img/',
  IMG_URL: M_IMG + "resimg/gravity/",
  // IMG_URL: M_IMG,

  // IMG_URL: BASE_URL + 'resimg/gravity/img/',

  S3_BUCKET: `gravity-s3bk`,
  // S3_BUCKET: `gravity-s3`,
  S3_REGION: `ap-northeast-2`,

  // ***************** 어싱크 키
  AS_KEY_LOGIN_INFO: "as_key_login_info",

  // ***************** 서버 코드
  TYPE_BASIC: "1",
  TYPE_PREMIUM: "2",

  // ****************** 공통 사용 코드
  APP_VER: "0.1.13", // 0.1.8 --(23/6/1)--> 0.1.9 --(23/6/9)--> 0.1.10 --(23/7/26)--> 0.1.11 --> 0.1.12(테스트결제) -- (08.28) --> 0.1.13(실결제)
  IS_LOG: false,
};

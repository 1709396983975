import * as ITF from "../../constants/Interface";
import { JoinFormData } from "../../mobile-pages/Interface/Interface";

export const TOGGLE_CALENDAR_MODAL = "TOGGLE_CALENDAR_MODAL";

export const toggleCalendarModal = () => ({
  type: TOGGLE_CALENDAR_MODAL,
});

const setRxLoginInfo = (userObj: ITF.LoginInfo) => {
  return {
    type: "SET_USER",
    payload: userObj,
  };
};

const setMLoginInfo = (userObj: ITF.LoginInfo) => {
  return {
    type: "SET_USER",
    payload: userObj,
  };
};

const setSignUpData = (signUpData: JoinFormData) => {
  return {
    type: "SET_SIGN_UP_DATA",
    payload: signUpData,
  };
};

export const setVideoUrls = (urls: string) => {
  return {
    type: "SET_VIDEO_URLS",
    payload: urls,
  };
};

const setRememberIdEnabled = (isEnabled: any) => {
  return {
    type: "SET_REMEMBER_ID_ENABLED",
    payload: isEnabled,
  };
};

const setAutoLoginEnabled = (isEnabled: any) => {
  return {
    type: "SET_AUTO_LOGIN_ENABLED",
    payload: isEnabled,
  };
};

const logOut = () => {
  return {
    type: "LOG_OUT",
  };
};

export const TOGGLE_ITEM_SELECTION = "TOGGLE_ITEM_SELECTION";
export const toggleItemSelection = (configNo: string, isSelected: boolean) => ({
  type: TOGGLE_ITEM_SELECTION,
  payload: { configNo, isSelected },
});

const allActions = {
  setRxLoginInfo,
  setMLoginInfo,
  setSignUpData,
  setVideoUrls,
  setRememberIdEnabled,
  setAutoLoginEnabled,
  logOut,
  toggleCalendarModal,
  toggleItemSelection,
};

export default allActions;

import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useMutation } from "react-query";
import { useDispatch, useSelector } from "react-redux";
import * as ServerApiM from "../constants/ServerApiM";
import allActions from "../component/redux/allActions";
import "../css/Mobile.css";
import AsyncStorage from "@react-native-async-storage/async-storage";
import Modal from "react-modal";
import styled from "styled-components";
import { RootState } from "../component/redux/rootReducer";
import backGround from "../img/new1.png";
import id from "../img/id.png";
import passwordImg from "../img/password.png";
import hide from "../img/visibility_off.png";
import NewButtons from "./Compornent/NewButtons";
import BackButton from "./Compornent/BackButton";

Modal.setAppElement("#root");

const ForgotPw = styled.p`
  color: rgba(242, 242, 242, 0.8);

  &:active {
    color: #333;
  }
`;

const Container = styled.div`
  position: relative;
  width: 100%;
  min-height: 100vh;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const LoginTitle = styled.div`
  width: 100%;
  text-align: center;
  position: absolute;
  top: 35%;
  left: 50%;
  transform: translate(-50%, 50%);
  color: #fff;
  font-family: Poppins-Italic, serif;
  font-size: 2.6rem;
  line-height: 3rem;
  font-weight: 600;

  @media (max-height: 500px) {
    display: none;
  }
`;

const LoginContainer = styled.div`
  width: 80%;
  margin: auto;
  transition: transform 0.3s ease-in-out;
  transform: translateY(100px);

  @media (max-height: 500px) {
    transform: translateY(50px);
  }
`;

const LoginBox = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  align-items: center;
  background-color: rgba(49, 54, 84, 0.3);
  width: 100%;
  padding: 10px 15px;
  margin-top: 25px;
  border-radius: 10px;
  border: none;

  > img {
    height: 16px;
  }
`;

const LoginBoxStyle = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  align-items: center;
  margin: 10px auto 10px auto;
  background: transparent;

  input {
    background: transparent;
    border: none;
    color: #eeeeee;
    margin-left: 20px;
  }

  img {
    width: 20px;
  }
`;

const BackGround = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-image: linear-gradient(
      to bottom,
      rgba(3, 7, 23, 0.2),
      rgba(3, 7, 23, 0.9),
      rgba(3, 7, 23, 1)
    ),
    url(${backGround});
  background-size: cover;
  background-position: center center;
  z-index: -1;
`;

const ButtonWrap = styled.div`
  width: 100%;
  position: absolute;
  bottom: 10%;
  left: 50%;
  transform: translate(-50%, 50%);
`;

const MLogin = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [userid, setUserid] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [dnStatus, setDnStatus] = useState<
    "ready" | "loading" | "error" | "success"
  >("ready");
  const [rememberId, setRememberId] = useState(false);

  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [modalMessage, setModalMessage] = useState("");
  const MLoginInfo = useSelector((state: RootState) => state.MLoginInfo);

  // iOS 체크
  const isIOS = /iPad|iPhone|iPod/.test(navigator.userAgent);

  useEffect(() => {
    const loadRememberedId = async () => {
      const savedUserId = await AsyncStorage.getItem("@remember_user_id");
      if (savedUserId) {
        setUserid(savedUserId);
      }
    };

    loadRememberedId();
  }, []);

  // 자동 로그인을 위한 별도의 useEffect
  useEffect(() => {
    const autoLogin = async () => {
      try {
        const storedLoginInfo =
          (await AsyncStorage.getItem("@login_info")) ||
          localStorage.getItem("@login_info");

        if (storedLoginInfo) {
          const loginData = JSON.parse(storedLoginInfo);
          console.log("stored loginData", loginData);

          if (loginData.userid && loginData.password) {
            // 상태 업데이트를 Promise로 감싸서 완료 시점을 확인
            await Promise.all([
              new Promise<void>((resolve) => {
                setUserid(loginData.userid);
                resolve();
              }),
              new Promise<void>((resolve) => {
                setPassword(loginData.password);
                resolve();
              }),
            ]);

            // 상태 업데이트가 완료된 후 로그인 시도
            const data = {
              userid: loginData.userid,
              password: loginData.password,
            };

            console.log("attempting auto login with:", data);
            const response = await ServerApiM.m_app_login(data);

            if (response && response.rsp_code === "100") {
              // 로그인 성공 처리
              const newLoginData = {
                ...response.data,
                password: loginData.password,
                userid: loginData.userid,
              };
              dispatch(allActions.setMLoginInfo(newLoginData));
              dispatch(allActions.setRxLoginInfo(newLoginData));

              history.push(
                response.data.member_type === "m1" ||
                  response.data.member_type === "m2"
                  ? "/DayProgramM"
                  : "/home"
              );
            }
          }
        }
      } catch (error) {
        console.error("Auto login error:", error);
      }
    };

    autoLogin();
  }, []); // 컴포넌트 마운트 시 한 번만 실행

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const openModal = (message: any) => {
    setModalMessage(message);
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
  };

  const handleLogin = async (e: any) => {
    e.preventDefault();
    try {
      const data = {
        userid: userid,
        password: password,
      };
      console.log("data", data);
      const response = await ServerApiM.m_app_login(data);
      console.log("response", response);
      if (response && response.rsp_code === "100") {
        console.log("로그인 성공");
        const loginData = {
          ...response.data,
          password: password, // loginPassword 대신 현재 입력된 password 사용
          userid: userid, // userid도 명시적으로 추가
        };

        dispatch(allActions.setMLoginInfo(loginData));
        dispatch(allActions.setRxLoginInfo(loginData));

        console.log("loginData", loginData);
        // 로그인 정보 저장 시에도 동일한 데이터 사용
        const loginInfoToStore = JSON.stringify(loginData);

        // 웹 환경에서 로그인 정보 저장
        if (typeof window !== "undefined") {
          localStorage.setItem("@login_info", loginInfoToStore);
        }

        // React Native 환경에서 로그인 정보 저장
        if (typeof AsyncStorage !== "undefined") {
          await AsyncStorage.setItem("@login_info", loginInfoToStore);
        }

        if (window.ReactNativeWebView) {
          window.ReactNativeWebView.postMessage(
            JSON.stringify({
              type: "LOGIN_SUCCESS",
              isLoggedIn: true,
            })
          );
        }

        history.push(
          response.data.member_type === "m1" ||
            response.data.member_type === "m2"
            ? "/DayProgramM"
            : "/home"
        );
      } else if (response && response.rsp_code === "200") {
        console.log("아이디 혹은 패스워드를 확인해주세요.");
        setDnStatus("error");
        setTimeout(() => {
          openModal("아이디 혹은 패스워드를 확인해주세요.");
          setTimeout(() => {
            setDnStatus("ready");
          }, 1000);
        }, 500);
      } else if (response && response.rsp_code === "205") {
        // 승인전 로그인 성공
        history.push("/DeniedLogin");
        setDnStatus("success");
      } else if (response && response.rsp_code === "220") {
        setDnStatus("error");
        openModal("아직 결제전입니다. 결제후 이용해주세요");
        dispatch(allActions.setMLoginInfo(response.data));
        dispatch(allActions.setRxLoginInfo(response.data));
        // iOS 체크 후 라우트 설정
        if (isIOS) {
          history.push(
            { pathname: "/DeniedLogin" },
            { MLoginInfo: MLoginInfo }
          );
        } else {
          history.push({ pathname: "/SABPay" }, { MLoginInfo: MLoginInfo });
        }
      } else {
        // 기타 로그인 실패 처리
        setDnStatus("error");
        openModal("로그인 오류");
        setTimeout(() => {
          setDnStatus("ready");
        }, 1000);
      }
    } catch (error) {
      console.error("Error during login:", error);
    }
  };

  // const { mutate: loginMutate } = useMutation(ServerApiM.m_app_login, {
  //   onSuccess: async (result) => {
  //     if (result && result.rsp_code === "100") {
  //       const loginData = {
  //         ...result.data,
  //         password: password, // loginPassword 대신 현재 입력된 password 사용
  //         userid: userid, // userid도 명시적으로 추가
  //       };

  //       dispatch(allActions.setMLoginInfo(loginData));
  //       dispatch(allActions.setRxLoginInfo(loginData));

  //       console.log("loginData", loginData);
  //       // 로그인 정보 저장 시에도 동일한 데이터 사용
  //       const loginInfoToStore = JSON.stringify(loginData);

  //       // 웹 환경에서 로그인 정보 저장
  //       if (typeof window !== "undefined") {
  //         localStorage.setItem("@login_info", loginInfoToStore);
  //       }

  //       // React Native 환경에서 로그인 정보 저장
  //       if (typeof AsyncStorage !== "undefined") {
  //         await AsyncStorage.setItem("@login_info", loginInfoToStore);
  //       }

  //       if (window.ReactNativeWebView) {
  //         window.ReactNativeWebView.postMessage(
  //           JSON.stringify({
  //             type: "LOGIN_SUCCESS",
  //             isLoggedIn: true,
  //           })
  //         );
  //       }

  //       history.push(
  //         result.data.member_type === "m1" || result.data.member_type === "m2"
  //           ? "/DayProgramM"
  //           : "/home"
  //       );
  //     } else if (result && result.rsp_code === "200") {
  //       // 아이디나 패스워드 오류 처리
  //       setDnStatus("error");
  //       setTimeout(() => {
  //         openModal("아이디 혹은 패스워드를 확인해주세요.");
  //         setTimeout(() => {
  //           setDnStatus("ready");
  //         }, 1000);
  //       }, 500);
  //     } else if (result.rsp_code === "205") {
  //       // 승인전 로그인 성공
  //       history.push("/DeniedLogin");
  //       setDnStatus("success");
  //     } else if (result.rsp_code === "220") {
  //       setDnStatus("error");
  //       openModal("아직 결제전입니다. 결제후 이용해주세요");
  //       dispatch(allActions.setMLoginInfo(result.data));
  //       dispatch(allActions.setRxLoginInfo(result.data));
  //       // iOS 체크 후 라우트 설정
  //       if (isIOS) {
  //         history.push(
  //           { pathname: "/DeniedLogin" },
  //           { MLoginInfo: MLoginInfo }
  //         );
  //       } else {
  //         history.push({ pathname: "/SABPay" }, { MLoginInfo: MLoginInfo });
  //       }
  //     } else {
  //       // 기타 로그인 실패 처리
  //       setDnStatus("error");
  //       openModal("로그인 오류");
  //       setTimeout(() => {
  //         setDnStatus("ready");
  //       }, 1000);
  //     }
  //   },
  //   onError: (error: any) => {
  //     setDnStatus("error");
  //     openModal("로그인 오류: " + error.message);
  //     setTimeout(() => {
  //       setDnStatus("ready");
  //     }, 1000);
  //   },
  // });

  const validateInputs = () => {
    if (!userid || !password) {
      openModal("아이디와 패스워드를 모두 입력하세요");
      setDnStatus("error");
      setTimeout(() => {
        setDnStatus("ready");
      }, 1000);
      return false;
    }
    return true;
  };

  const handleLoginClick = async () => {
    if (!validateInputs()) {
      return;
    }
    setDnStatus("loading");

    if (rememberId) {
      await AsyncStorage.setItem("@remember_user_id", userid);
    } else {
      await AsyncStorage.removeItem("@remember_user_id");
    }

    setTimeout(() => {
      setDnStatus("success");
      setTimeout(() => {
        // loginMutate({ userid, password });
        handleLogin({ preventDefault: () => {} });
        setDnStatus("ready");
      }, 1000);
    }, 1000);
  };

  const handleForgotPassWard = () => {
    history.push({ pathname: "/ForgotPassword" });
  };

  return (
    <Container>
      <BackGround />
      <LoginTitle>Login</LoginTitle>
      <LoginContainer>
        <form onSubmit={handleLogin}>
          <LoginBox>
            <LoginBoxStyle>
              <img src={id} alt="ID" />
              <input
                type="text"
                required
                autoComplete="false"
                value={userid}
                placeholder="ID - Email"
                onChange={(evt) => setUserid(evt.target.value)}
              ></input>
            </LoginBoxStyle>
          </LoginBox>
          <LoginBox>
            <LoginBoxStyle>
              <img src={passwordImg} alt="PASSWORD" />
              <input
                type={showPassword ? "text" : "password"}
                required
                autoComplete="false"
                value={password}
                placeholder="Password"
                onChange={(evt) => setPassword(evt.target.value)}
              ></input>
            </LoginBoxStyle>
            <img
              src={hide}
              alt="Toggle visibility"
              onClick={togglePasswordVisibility}
              style={{ cursor: "pointer" }}
            />
          </LoginBox>

          <div
            style={{
              width: "100%",
              margin: "25px auto 40px auto",
              cursor: "pointer",
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <div
              className="loginCheckbox"
              style={{
                display: "flex",
              }}
            >
              <input
                type="checkbox"
                id="rememberId"
                checked={rememberId}
                onChange={(e) => setRememberId(e.target.checked)}
                style={{
                  marginRight: "5px",
                }}
              />
              <label
                style={{
                  color: "rgba(242,242,242,0.8)",
                }}
                htmlFor="rememberId"
              >
                Remember ID
              </label>
            </div>
            <ForgotPw onClick={handleForgotPassWard}>
              ( Forgot Passward? )
            </ForgotPw>
          </div>
        </form>
      </LoginContainer>
      <ButtonWrap>
        <NewButtons
          text="Login"
          dnStatus={dnStatus}
          myClick={handleLoginClick}
        />
      </ButtonWrap>

      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        contentLabel="Alert Modal"
        className="modalAlertBox"
        style={{
          overlay: {
            backgroundColor: "rgba(0, 0, 0, 0.75)",
            zIndex: 99,
          },
        }}
      >
        <h2>{modalMessage}</h2>
        <button onClick={closeModal}>OK</button>
      </Modal>
    </Container>
  );
};

export default MLogin;

import { useEffect, useCallback, useState, useRef } from "react";
import { useHistory } from "react-router-dom";
import ReactPlayer from "react-player/lazy";
import ReactAudioPlayer from "react-audio-player";
import {
  faVolumeMute,
  faVolumeHigh,
  faPlay,
  faPause,
  faForward,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useSelector } from "react-redux";
import { Modal } from "antd";
import * as MyUtil from "../constants/MyUtil";
import * as ServerApi from "../constants/ServerApi";
import logoArrowOnly from "../img/symbol.png";
import ic_wait_logo from "../img/logo_mobile.png";
import gifHeartQuick from "../img/gif_heart_red_q.gif";
import gifHeartSlow from "../img/gif_heart_red_s.gif";
import { RootState } from "../component/redux/rootReducer";
import Loader from "../component/Loader";
import "../css/ModalAntd.css";

// *** local path audio file NOT WORKING in android.
// http://simonmacdonald.blogspot.com/2011/05/using-media-class-in-phonegap.html?m=1

// *** .mp3 확장자 import 이슈
// https://stackoverflow.com/questions/59247861/how-to-import-a-sound-file-into-react-typescript-component
import sound_go from "../music/sound_go.ogg";
import MemoPlayer from "../component/MemoPlayer";
import styled from "styled-components";

const SBVideoPlay = ({ location }: any) => {
  const { rxLoginInfo } = useSelector(
    (state: RootState) => state.rxLoginInfo,
    (prev, next) => {
      return prev.rxLoginInfo === next.rxLoginInfo;
    }
  );
  const my_center_no = rxLoginInfo.center_no;
  const history = useHistory();
  const [pro_no, setPro_no] = useState(
    MyUtil._isNull(location?.state?.pro_no) ? "" : location?.state?.pro_no
  );
  const [selectProgram, setSelectProgram] = useState<any>([]);
  const [playIndex, setPlayIndex] = useState(0);
  const [musicIdx, setMusicIdx] = useState(0);
  const [playSoundFile, setPlaySoundFile] = useState("sound_go");
  const [playStatus, setPlayStatus] = useState("p");
  const [volumeLevel, setVolumeLevel] = useState(0.7);
  const [countVolumeLevel, setCountVolumeLevel] = useState(0);
  const [isLogoScreen, setIsLogoScreen] = useState(true);
  const [isBgSound, setIsBgSound] = useState(true);
  const [isCountSound, setIsCountSound] = useState(false);
  const [isPlayEx, setIsPlayEx] = useState(false);
  const [isTimerVisible, setIsTimerVisible] = useState(false);
  const [exTimer, setExTimer] = useState<number>(-1);
  const audioPlayer = useRef<any>(null);
  const countPlayer = useRef<any>(null);
  const interval = useRef<any>(null);
  const [tempVideo, setTempVideo] = useState<any>([]);
  const [mvUrl2, setMvUrl2] = useState<any>("");
  const [mvUrl3, setMvUrl3] = useState<any>("");
  const [loading, setLoading] = useState<boolean>(true);
  const [arrMusic, setArrMusic] = useState<any>([]);
  const [totalVideos, setTotalVideos] = useState<number>(0);
  const [currentVideoIndex, setCurrentVideoIndex] = useState(1);
  const [currentVideo, setCurrentVideo] = useState<any>([]);

  //  고용량
  // const tempVideo = [
  //   'https://gravity-s3bk.s3.ap-northeast-2.amazonaws.com/video/1657877338196_%EB%B8%8C%EB%A6%BF%EC%A7%80+%EC%B2%B4%EC%8A%A4%ED%8A%B8+%ED%94%84%EB%A0%88%EC%8A%A4.mp4',
  //   'https://gravity-s3bk.s3.ap-northeast-2.amazonaws.com/video/1663925412760_Side+band+start+position.mp4',
  //   'https://gravity-s3bk.s3.ap-northeast-2.amazonaws.com/video/1664966068399_One+leg+knee+extension+(out+side).mp4',
  //   'https://gravity-s3bk.s3.ap-northeast-2.amazonaws.com/video/1665994672538_Upright+supine.mp4',
  //   'https://gravity-s3bk.s3.ap-northeast-2.amazonaws.com/video/1663923833495_4POINT+START+POSITION.mp4',
  //   'https://gravity-s3bk.s3.ap-northeast-2.amazonaws.com/video/1665558024294_Arm circle reverse.mp4',
  //   'https://gravity-s3bk.s3.ap-northeast-2.amazonaws.com/video/1665558358157_Cross body shoulder raise.mp4',
  //   'https://gravity-s3bk.s3.ap-northeast-2.amazonaws.com/video/1665558601939_Shrug.mp4',
  // ]

  // 30mb 언저리
  // const tempVideo = [
  //   'https://gravity-s3bk.s3.ap-northeast-2.amazonaws.com/video/1657877945258_Dipbar+triple+extension.mp4',
  //   'https://gravity-s3bk.s3.ap-northeast-2.amazonaws.com/video/1662996366031_Combine+cable.mp4',
  //   'https://gravity-s3bk.s3.ap-northeast-2.amazonaws.com/video/1663924821967_Forward+seated.mp4',
  //   'https://gravity-s3bk.s3.ap-northeast-2.amazonaws.com/video/1663924865890_Sprint+start+position.mp4',
  //   'https://gravity-s3bk.s3.ap-northeast-2.amazonaws.com/video/1664360821117_Shouler+press+(hinge+to+tall+kneeling).mp4',
  //   'https://gravity-s3bk.s3.ap-northeast-2.amazonaws.com/video/1664360833056_Shouler+press+(tall+kneeling).mp4',
  //   'https://gravity-s3bk.s3.ap-northeast-2.amazonaws.com/video/1664467978193_Inverted+pike+(knee+touch).mp4',
  //   'https://gravity-s3bk.s3.ap-northeast-2.amazonaws.com/video/1664633682091_Step+down.mp4',
  // ]

  const EMPTY_REST_TIME = 4;
  // **********************************************************************************************************************************************
  // let EX_TIME = 0;
  // let REST_TIME = 0;
  // let WAIT_TIME = 0;
  // const isRestTime = false;

  // let EX_TIME = 0;
  // let REST_TIME = 0; // 쉬는 시간
  // let WAIT_TIME = 0; // 대기 시간
  // let isRestTime = false;
  // **********************************************************************************************************************************************

  // MyUtil._consoleLog("\n\n\n*********************************************************************************************");
  // MyUtil._consoleLog("EX_TIME : " + EX_TIME);
  // MyUtil._consoleLog("REST_TIME : " + REST_TIME);
  // MyUtil._consoleLog("WAIT_TIME : " + WAIT_TIME);
  // MyUtil._consoleLog("isRestTime : " + isRestTime);

  const mParams = useRef<any>({
    exTimer: 0,
    exStatus: "p",
    playIndex: 0,
    EX_TIME: 0,
    REST_TIME: 0,
    WAIT_TIME: 0,
    isRestTime: false,
    videoLength: 0,
    autoYn: "n",
  }); // exTstatus : p 준비 , e 운동 , r 쉬는시간

  useEffect(() => {
    console.log("mParams", mParams.current);
  }, [mParams.current]);

  useEffect(() => {
    async function fetchData() {
      m_center_pro_m_dt(true);
    }

    fetchData();
    return () => {
      if (interval.current) {
        clearInterval(interval.current);
      }
    };
  }, []);

  const m_center_pro_m_dt = useCallback(
    async (isFirst) => {
      let result: any = await ServerApi.m_center_pro_m_dt({ pro_no: pro_no });
      console.log("result", result.data);
      const { isOk, data } = MyUtil._isResultSucces(
        "m_center_pro_m_dt",
        result
      );

      setCurrentVideo(data.pro_array[0].mv_array);

      let totalVideos = 0;
      for (let i = 0; i < data.pro_array.length; i++) {
        totalVideos += data.pro_array[i].mv_array.length;
      }
      setTotalVideos(totalVideos);

      if (isOk) {
        const musicData = {
          pro_no: data.pro_no,
          music_gb_check: data.auto_yn,
        };
        const result: any = await ServerApi.m_app_pro_my_play_list(musicData);
        setLoading(false);
        console.log("m_app_pro_my_play_list result", result);
        if (result.data.resObject.rsp_code === "100") {
          setArrMusic(result.data.resObject.array);
          console.log("arrMusic", arrMusic);
          let getIdx = musicIdx;
          getIdx = Math.floor(
            Math.random() * result.data.resObject.array.length
          );
          setMusicIdx(getIdx);
        } else {
          MyUtil._alertMsg("m_app_pro_my_play_list", result.DATA_RESULT);
        }

        mParams.current.autoYn = data?.auto_yn;
        const restTime = MyUtil._isNull(data?.br_time)
          ? EMPTY_REST_TIME
          : parseInt(data?.br_time); // 쉬는 시간
        console.log("restTime", restTime);

        const exercises = data.pro_array.map((exercise: any) => {
          const exTime = MyUtil._isNull(exercise.mv_array[0]?.ex_time)
            ? 0
            : parseInt(exercise.mv_array[0]?.ex_time);
          const waitTime = MyUtil._isNull(exercise.mv_array[0]?.wait_time)
            ? -1
            : parseInt(exercise.mv_array[0]?.wait_time) + 1; // 대기 시간
          return { exTime, waitTime };
        });

        mParams.current.EX_TIME = MyUtil._isNull(exercises[0]?.exTime)
          ? 0
          : exercises[0]?.exTime;
        mParams.current.REST_TIME = restTime;
        const WAIT_TIME = MyUtil._isNull(exercises[0]?.waitTime)
          ? -1
          : exercises[0]?.waitTime; // 대기 시간
        mParams.current.isRestTime = MyUtil._isNull(data?.br_time)
          ? false
          : true;
        mParams.current.exTimer = WAIT_TIME;
        mParams.current.WAIT_TIME = WAIT_TIME;
        mParams.current.videoLength = data?.pro_array?.length;

        // 최초 실행
        if (isFirst) {
          // br_time이 0이 아닐 경우에만 타이머 시작
          if (restTime > 0) {
            mParams.current.exTimer = restTime; // br_time을 exTimer에 설정
            setPlayStatus("p");
            setPlayIndex(0);
            setIsPlayEx(true);
            setIsTimerVisible(true);
            setIsLogoScreen(false);
            timerHandler();
          }
        } else {
          // 운동 다 끝나고 다시 시작할때
          mParams.current.exTimer = restTime;
          setPlayStatus("p");
          setPlayIndex(0);
          setIsPlayEx(true);
          setIsTimerVisible(true);
          setIsLogoScreen(false);
          timerHandler();
        }

        setSelectProgram(data);
        setTempVideo(data?.pro_array[0]?.mv_array);
        // console.log('*********** data?.pro_array[0]?.mv_array ************* : ' + JSON.stringify(data?.pro_array))
        audioPlayer.current.audioEl.current.load();
        audioPlayer.current.audioEl.current.play();
      } else {
        MyUtil._alertMsg("m_center_pro_m_dt", data);
      }
    },
    [pro_no]
  );

  useEffect(() => {
    if (tempVideo.length > 0) {
      const firstVideo = tempVideo[0];
      mParams.current.EX_TIME = parseInt(firstVideo.ex_time, 10) || 0;
      mParams.current.WAIT_TIME = parseInt(firstVideo.wait_time, 10) || 0;
      console.log(
        "첫 번째 비디오를 기준으로 EX_TIME과 WAIT_TIME을 설정했습니다:",
        firstVideo
      );
    } else {
      console.warn("현재 시퀀스에 해당하는 비디오가 없습니다.");
    }
  }, [tempVideo]);

  const timerHandler = useCallback(async () => {
    if (interval.current) {
      clearInterval(interval.current);
    }
    console.log(
      "*********** mParams.current.EX_TIME ************* : " +
        mParams.current.EX_TIME
    );
    console.log(
      "*********** mParams.current.REST_TIME ************* : " +
        mParams.current.REST_TIME
    );
    console.log(
      "*********** mParams.current.WAIT_TIME ************* : " +
        mParams.current.WAIT_TIME
    );

    // 둘다 미 설정시 아무것도 안함
    if (mParams.current.EX_TIME === 0) {
      return setExTimer(-1);
    }

    interval.current = setInterval(function () {
      const curTime = mParams.current.exTimer;
      let nextTime: any = curTime - 1;
      let nowStatus = mParams.current.exStatus;
      let nextStatus = nowStatus;

      console.log("*********** nowStatus ************* : " + nowStatus);
      if (nextTime < 0) {
        if (nowStatus === "p") {
          nextStatus = "e";
          nextTime = mParams.current.EX_TIME;
          setIsTimerVisible(true);
          setIsPlayEx(true);
          setIsLogoScreen(false);
          setVolumeLevel(0.7);
          console.log("*********** nowStatus === 'p'  end *************");
        } else if (nowStatus === "e") {
          nextStatus = "r";
          nextTime = mParams.current.WAIT_TIME;
          setIsTimerVisible(true);
          setIsPlayEx(true);
          setIsLogoScreen(false);
          console.log("*********** nowStatus === 'e'  end *************");

          // // 다음 시퀀스의 EX_TIME과 WAIT_TIME 설정
          // const nextIndex = mParams.current.playIndex + 1; // 다음 인덱스
          // console.log("nextIndex", nextIndex);
          // if (tempVideo[nextIndex]) {
          //   mParams.current.EX_TIME = parseInt(tempVideo[nextIndex].ex_time);
          // }

          // ****** 휴식시간 설정시 다음 영상 미리 재생
          // if (REST_TIME !== 0) {
          if (mParams.current.WAIT_TIME > 0) {
            handleNextVideo();
            setVolumeLevel(0.3);

            // ****** 휴식 미설정시 자동 진행 안함
          } else {
            handleNextVideo();
            setIsPlayEx(true);
            // setIsLogoScreen(true);
            // setIsPlayEx(false);

            setVolumeLevel(0.3);
            setIsTimerVisible(false);
            setExTimer(-1);
            if (interval.current) {
              clearInterval(interval.current);
            }
            return;
          }
        } else if (nowStatus === "r") {
          nextStatus = "e";
          nextTime = mParams.current.EX_TIME;
          setVolumeLevel(0.7);
          console.log("*********** nowStatus === 'r' *************");

          // const nextIndex = mParams.current.playIndex + 1; // 다음 인덱스
          // console.log("nextIndex2", nextIndex);
          // if (tempVideo[nextIndex]) {
          //   mParams.current.WAIT_TIME = parseInt(
          //     tempVideo[nextIndex].wait_time
          //   );
          // }

          // 휴식시간 미 설정시 다음 영상 진행 안함
          // if (REST_TIME === 0) { handleNextVideo(); };
        }
      }

      mParams.current.exStatus = nextStatus;
      mParams.current.exTimer = nextTime;

      if (parseInt(nextTime) - 1 === 2) {
        setCountVolumeLevel(1);
        setVolumeLevel(0.1); // -------- <<<<<<<<<<<<<<
        setPlaySoundFile("sound_time");
        countPlayer.current.audioEl.current.play();

        if (nextStatus === "e") {
          // 마지막엔 스톱 음성
          if (
            mParams.current.playIndex ===
            selectProgram?.pro_array?.length - 1
          ) {
            setTimeout(() => {
              setPlaySoundFile("sound_stop");
              if (nowStatus === "p") {
                setVolumeLevel(0.7);
              } else {
                setVolumeLevel(0.3);
              } // -------- <<<<<<<<<<<<<<
              countPlayer.current.audioEl.current.play();
            }, 3000);
          } else {
            if (mParams.current.isRestTime) {
              setTimeout(() => {
                setPlaySoundFile("sound_rest");
                if (nowStatus === "p") {
                  setVolumeLevel(0.7);
                } else {
                  setVolumeLevel(0.3);
                } // -------- <<<<<<<<<<<<<<
                countPlayer.current.audioEl.current.play();
              }, 3000);
            } else {
              setTimeout(() => {
                setPlaySoundFile("sound_stop");
                if (nowStatus === "p") {
                  setVolumeLevel(0.7);
                } else {
                  setVolumeLevel(0.3);
                } // -------- <<<<<<<<<<<<<<
                countPlayer.current.audioEl.current.play();
              }, 3000);
            }
          }
        } else {
          setTimeout(() => {
            setPlaySoundFile("sound_go");
            if (nowStatus === "p") {
              setVolumeLevel(0.7);
            } else {
              setVolumeLevel(0.3);
            } // -------- <<<<<<<<<<<<<<
            countPlayer.current.audioEl.current.play();
          }, 3000);
        }
      }

      setExTimer(nextTime);
      setPlayStatus(nextStatus);
    }, 1000);
  }, [audioPlayer, countPlayer, selectProgram, tempVideo]);

  useEffect(() => {
    console.log(
      "*********** tempVideo *************",
      // tempVideo[mParams.current.playIndex]
      tempVideo[mParams.current.playIndex]
    );
    console.log("mParams.current.playIndex", mParams.current.playIndex);
    console.log("mParams.current.EX_TIME", mParams.current.EX_TIME);
    console.log("mParams.current.WAIT_TIME", mParams.current.WAIT_TIME);
  }, [tempVideo, mParams.current.EX_TIME, mParams.current.WAIT_TIME]);

  useEffect(() => {
    async function fetchData() {
      // console.log('******** playIndex HOOK ************* : ' + playIndex);
      setLoading(true);
      if (playIndex >= 0 && !MyUtil._isNull(selectProgram)) {
        // if (mParams.current.playIndex !== (selectProgram?.pro_array?.length - 1)) {
        setTempVideo(selectProgram?.pro_array[playIndex]?.mv_array);
        // }
      }
      setLoading(false);
    }
    fetchData();
  }, [playIndex]);

  useEffect(() => {
    if (audioPlayer?.current) {
      if (!MyUtil._isNull(arrMusic)) {
        if (isBgSound) {
          audioPlayer.current.audioEl.current.load();
          audioPlayer.current.audioEl.current.play();
        } else {
          audioPlayer.current.audioEl.current.pause();
        }
      }
    }
  }, [isBgSound]);

  useEffect(() => {
    // if (countPlayer?.current) {
    if (isCountSound) {
      countPlayer.current.audioEl.current.play();
    } else {
      countPlayer.current.audioEl.current.pause();
      countPlayer.current.audioEl.current.currentTime = 0;
    }
    countPlayer.current.audioEl.current.play();
    // };
  }, [isCountSound]);

  const handleNextVideo = useCallback(() => {
    const videoAllLength = mParams.current.videoLength;
    // MyUtil._consoleLog(
    //   "******** mParams.current.playIndex ************* : " +
    //     mParams.current.playIndex
    // );
    console.log(
      "******** mParams.current.playIndex ************* : " +
        mParams.current.playIndex
    );
    // MyUtil._consoleLog(
    //   "******** videoAllLength ************* : " + videoAllLength
    // );
    // MyUtil._consoleLog('******** (selectProgram?.pro_array?.length - 1) ************* : ' + JSON.stringify(selectProgram?.pro_array))

    const WAIT_TIME = mParams.current.WAIT_TIME;

    // 휴식시간이 있으면 영상을 미리 보여주기 때문에 분기 처리
    // 운동 시간이 0인 경우
    if (mParams.current.EX_TIME === 0) {
      if (mParams.current.playIndex + 1 === videoAllLength - 1) {
        endEx(); // 마지막 비디오인 경우 종료
        console.log("*********** 마지막 비디오인 경우 종료 *************");
      } else if (mParams.current.playIndex === videoAllLength - 1) {
        // 마지막 비디오인 경우
        const nextIndex = mParams.current.playIndex; // 현재 인덱스 유지
        mParams.current.playIndex = nextIndex;
        setPlayIndex(nextIndex);
        console.log("*********** 마지막 비디오인 경우? *************");
      } else {
        const nextIndex = mParams.current.playIndex + 1; // 다음 인덱스
        mParams.current.playIndex = nextIndex;
        setPlayIndex(nextIndex);

        console.log("*********** 다음 인덱스 *************");
      }
    } else {
      if (mParams.current.playIndex === videoAllLength - 1) {
        endEx(); // 마지막 비디오인 경우 종료
        console.log("*********** 마지막 비디오인 경우 종료222 *************");
      } else {
        const nextIndex = mParams.current.playIndex + 1; // 다음 인덱스
        mParams.current.playIndex = nextIndex;
        setPlayIndex(nextIndex);
        console.log("*********** 다음 인덱스222 *************");
      }
    }
  }, [selectProgram]);

  const endEx = useCallback(async () => {
    const WAIT_TIME = mParams.current.WAIT_TIME;
    if (interval.current) {
      clearInterval(interval.current);
    }
    setIsPlayEx(false);
    setIsTimerVisible(false);
    setPlayIndex(0);
    setPlayStatus("p");
    setExTimer(WAIT_TIME);
    setIsLogoScreen(true);
    mParams.current.exTimer = WAIT_TIME;
    mParams.current.exStatus = "p";
    mParams.current.playIndex = 0;

    if (mParams.current.autoYn === "n") {
      Modal.info({ content: "Congratulations on your mission :)" });
    } else {
      setLoading(true);
      setTimeout(() => {
        m_center_pro_m_dt(false);
      }, 200);
    }
  }, []);

  useEffect(() => {
    // 현재 시퀀스의 비디오 수를 계산
    let totalVideosBefore = 0;
    for (let i = 0; i < playIndex; i++) {
      totalVideosBefore += selectProgram.pro_array[i].mv_array.length; // 이전 시퀀스의 비디오 수 합산
    }
    // 현재 비디오 순번 업데이트
    setCurrentVideoIndex(totalVideosBefore + 1); // 현재 시퀀스의 첫 번째 비디오를 포함
  }, [playIndex, selectProgram]);

  // SSE 리모컨
  // useEffect(() => {
  //   const eventSource = new EventSource("http://3.37.242.5:4000/events");
  //   let isInitialEvent = true;
  //   // let lastEventTime = 0;
  //   // const THROTTLE_DELAY = 500; // 0.5초

  //   eventSource.onmessage = function (event) {
  //     if (isInitialEvent) {
  //       isInitialEvent = false;
  //       // console.log("Initial SSE event ignored");
  //       return;
  //     }

  //     // const currentTime = Date.now();
  //     // if (currentTime - lastEventTime < THROTTLE_DELAY) {
  //     //   return; // 0.5초 이내의 이벤트는 무시
  //     // }
  //     // lastEventTime = currentTime;

  //     const { value: newNumber, center_no } = JSON.parse(event.data);
  //     console.log("넘어오는 값들", event.data);

  //     const currentCenterNo = location?.state?.center_no;
  //     console.log("Current center_no:", currentCenterNo);

  //     // PLAY/PAUSE 처리
  //     if (newNumber === "PLAY") {
  //       setIsPlayEx(true);
  //       setIsLogoScreen(false);
  //       setIsTimerVisible(true);

  //       mParams.current.exStatus = "r";
  //       setPlayStatus("r");
  //       timerHandler();
  //       return;
  //     }

  //     if (newNumber === "PAUSE") {
  //       if (interval.current) {
  //         clearInterval(interval.current);
  //       }
  //       setCountVolumeLevel(0);
  //       if (countPlayer) {
  //         countPlayer.current.audioEl.current.currentTime = 0;
  //         countPlayer.current.audioEl.current.pause();
  //       }

  //       setIsPlayEx(false);
  //       setIsTimerVisible(false);
  //       setPlayStatus("p");
  //       mParams.current.exStatus = "p";
  //       return;
  //     }

  //     if (newNumber === "C") {
  //       return;
  //     }

  //     if (center_no !== (location?.state?.center_no ?? my_center_no)) {
  //       return;
  //     }
  //     const videoIndex = parseInt(newNumber, 10) - 1; // 1부터 시작하는 인덱스를 0으로 변환

  //     // selectProgram이 정의되어 있고 pro_array가 존재하는지 확인
  //     if (
  //       selectProgram?.pro_array &&
  //       videoIndex >= 0 &&
  //       videoIndex < selectProgram.pro_array.length
  //     ) {
  //       // 기존 클릭 핸들러 로직을 사용하여 상태 업데이트
  //       setCountVolumeLevel(0);
  //       if (countPlayer) {
  //         countPlayer.current.audioEl.current.currentTime = 0;
  //         countPlayer.current.audioEl.current.pause();
  //       }

  //       let nextTime = EMPTY_REST_TIME;
  //       mParams.current.playIndex = videoIndex;
  //       setPlayIndex(videoIndex);
  //       setIsPlayEx(true);
  //       setIsLogoScreen(false);
  //       setExTimer(nextTime);
  //       setIsTimerVisible(true);
  //       mParams.current.exTimer = nextTime;
  //       mParams.current.exStatus = "r";
  //       setPlayStatus("r");
  //       setExTimer(nextTime);

  //       timerHandler(); // 타이머 시작
  //       console.log(`비디오 ${videoIndex + 1}로 전환되었습니다.`);
  //     } else {
  //       console.error(
  //         "selectProgram이 정의되지 않았거나 pro_array가 없습니다."
  //       );
  //     }
  //   };

  //   return () => {
  //     eventSource.close();
  //   };
  // }, [selectProgram]);

  return (
    <div className="container-zero-row" style={{ backgroundColor: "#000000" }}>
      {!MyUtil._isNull(arrMusic) && (
        <ReactAudioPlayer
          ref={audioPlayer}
          style={{ display: "none" }}
          src={arrMusic[musicIdx].file_nm}
          autoPlay
          controls
          loop={false}
          volume={volumeLevel}
          onEnded={() => {
            let getIdx = musicIdx;

            // if (musicIdx >= (arrMusic.length - 1)) {
            //   getIdx = 0;
            // } else {
            //   getIdx = getIdx + 1;
            // };

            if (arrMusic.length > 1) {
              while (getIdx == musicIdx) {
                getIdx = Math.floor(Math.random() * arrMusic.length);
              }
            }

            setMusicIdx(getIdx);
            audioPlayer.current.audioEl.current.pause();

            setTimeout(() => {
              audioPlayer.current.audioEl.current.load();
              audioPlayer.current.audioEl.current.play();
            }, 500);
          }}
        />
      )}

      <ReactAudioPlayer
        ref={countPlayer}
        style={{ display: "none" }}
        src={
          playSoundFile === "sound_time"
            ? "https://gravity-s3bk.s3.ap-northeast-2.amazonaws.com/bg/amthreesecs.mp3"
            : playSoundFile === "sound_rest"
            ? "https://gravity-s3bk.s3.ap-northeast-2.amazonaws.com/bg/amrest.mp3"
            : playSoundFile === "sound_go"
            ? "https://gravity-s3bk.s3.ap-northeast-2.amazonaws.com/bg/amgo.mp3"
            : "https://gravity-s3bk.s3.ap-northeast-2.amazonaws.com/bg/amrest.mp3"
        }
        autoPlay={false}
        controls
        loop={false}
        volume={countVolumeLevel}
        onEnded={() => {}}
      />

      {loading ? (
        <Loader />
      ) : (
        <div className="sbvideoplay-contain" style={{ overflowY: "hidden" }}>
          <div className="sbvideoplay-header" style={{}}>
            {isTimerVisible ? (
              <div
                style={{
                  display: "flex",
                  width: "100%",
                  flexDirection: "row",
                  alignItems: "center",
                }}
              >
                {/* 왼쪽 바 영역 제외 */}
                <div style={{ width: 24, height: 1 }}></div>

                <div
                  style={{
                    display: "flex",
                    flex: 1,
                    justifyContent: "start",
                    alignItems: "center",
                    flexDirection: "row",
                  }}
                >
                  <span className="sbvideoplay-header-text">WORKOUT </span>

                  {playStatus === "e" ? (
                    <img
                      style={{
                        width: "30px",
                        height: "30px",
                        marginLeft: "5px",
                      }}
                      src={gifHeartQuick}
                      alt="logo image"
                    />
                  ) : (
                    <img
                      style={{
                        width: "30px",
                        height: "30px",
                        marginLeft: "5px",
                      }}
                      src={gifHeartSlow}
                      alt="logo image"
                    />
                  )}
                </div>

                <div
                  style={{
                    display: "flex",
                    flex: 1,
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  {exTimer >= 0 && (
                    <span
                      className="sbvideoplay-header-extext"
                      style={{
                        color:
                          playStatus === "p"
                            ? "#8a8a8a"
                            : playStatus === "e"
                            ? "#ff0000"
                            : "#55ff55",
                      }}
                    >
                      {(mParams.current.exStatus === "p" ||
                        mParams.current.exStatus === "r" ||
                        mParams.current.exStatus === "w") &&
                      exTimer === 0
                        ? "GO!"
                        : mParams.current.exStatus === "e" && exTimer === 0
                        ? "STOP"
                        : MyUtil._secondToTimeSmallFormat(exTimer) + ""}
                    </span>

                    // isRestTime ? (
                    //   <span className='sbvideoplay-header-extext' style={{ color: playStatus === 'p' ? '#8a8a8a' : playStatus === 'e' ? '#ff0000' : '#55ff55' }}>{((mParams.current.exStatus === 'p' || mParams.current.exStatus === 'r' || mParams.current.exStatus === 'w') && exTimer === 0) ? 'GO!' : (mParams.current.exStatus === 'e' && exTimer === 0) ? 'STOP' : MyUtil._secondToTimeSmallFormat(exTimer) + ''}</span>
                    // ) : (
                    //   (mParams.current.exStatus === 'r' && exTimer < 4) ? (
                    //     <span className='sbvideoplay-header-extext' style={{ color: playStatus === 'p' ? '#8a8a8a' : playStatus === 'e' ? '#ff0000' : '#55ff55' }}>{((mParams.current.exStatus === 'p' || mParams.current.exStatus === 'r' || mParams.current.exStatus === 'w') && exTimer === 0) ? 'GO!' : (mParams.current.exStatus === 'e' && exTimer === 0) ? 'STOP' : MyUtil._secondToTimeSmallFormat(exTimer) + ''}</span>
                    //   ) : mParams.current.exStatus === 'e' ? (
                    //     <span className='sbvideoplay-header-extext' style={{ color: playStatus === 'p' ? '#8a8a8a' : playStatus === 'e' ? '#ff0000' : '#55ff55' }}>{((mParams.current.exStatus === 'p' || mParams.current.exStatus === 'r' || mParams.current.exStatus === 'w') && exTimer === 0) ? 'GO!' : (mParams.current.exStatus === 'e' && exTimer === 0) ? 'STOP' : MyUtil._secondToTimeSmallFormat(exTimer) + ''}</span>
                    //   ) : (
                    //     <div></div>
                    //   )
                    // )
                  )}
                </div>

                <div
                  style={{
                    display: "flex",
                    flex: 1,
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <span className="sbvideoplay-header-text">
                    {currentVideoIndex} / {`${totalVideos}`}
                  </span>
                </div>
              </div>
            ) : (
              <span
                className="sbvideoplay-header-text"
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
                onClick={() => {}}
              >
                DON'T THINK, LET'S MIIT
              </span>
            )}
          </div>

          <div
            style={{
              display: "flex",
              flexDirection: "row",
              width: "100%",
              height: "100%",
            }}
          >
            <div className="sbvideoplay-left">
              <img
                style={{
                  width: "30px",
                  height: "30px",
                  marginTop: "5px",
                  marginBottom: "7px",
                  marginLeft: "5px",
                  cursor: "pointer",
                }}
                src={logoArrowOnly}
                alt="logo image"
                onClick={() => {
                  history.goBack();
                }}
              />

              <div
                className="sbvideoplay-leftBtnWrap"
                style={{}}
                onClick={() => {
                  setCountVolumeLevel(0);
                  // countPlayer.current.audioEl.current.play();
                  if (countPlayer) {
                    countPlayer.current.audioEl.current.currentTime = 0;
                    countPlayer.current.audioEl.current.pause();
                  }

                  if (mParams.current.exStatus === "p") {
                    setPlayIndex(0);
                    setIsPlayEx(true);
                    setIsTimerVisible(true);
                    setIsLogoScreen(false);

                    mParams.current.exTimer = 4;
                    mParams.current.exStatus = "r";
                    timerHandler();
                  } else {
                    if (isPlayEx) {
                      setIsPlayEx(false);
                      setIsTimerVisible(false);
                      // setIsLogoScreen(true);
                      if (interval.current) {
                        clearInterval(interval.current);
                      }
                    } else {
                      setIsPlayEx(true);
                      setIsTimerVisible(true);
                      // setIsLogoScreen(false);
                      timerHandler();
                    }
                  }
                }}
              >
                <FontAwesomeIcon
                  style={{
                    width: "22px",
                    height: "22px",
                    marginLeft:
                      mParams.current.exStatus === "p"
                        ? "4px"
                        : !isPlayEx
                        ? "4px"
                        : "0px",
                  }}
                  icon={
                    mParams.current.exStatus === "p"
                      ? faPlay
                      : !isPlayEx
                      ? faPlay
                      : faPause
                  }
                />
              </div>

              <div
                className="sbvideoplay-leftBtnWrap"
                style={{}}
                onClick={() => {
                  let getIdx = musicIdx;

                  // if (musicIdx >= (arrMusic.length - 1)) {
                  //   getIdx = 0;
                  // } else {
                  //   getIdx = getIdx + 1;
                  // };
                  if (arrMusic.length > 1) {
                    while (getIdx == musicIdx) {
                      getIdx = Math.floor(Math.random() * arrMusic.length);
                    }
                  }

                  setIsBgSound(true);
                  setMusicIdx(getIdx);
                  audioPlayer.current.audioEl.current.pause();

                  setTimeout(() => {
                    audioPlayer.current.audioEl.current.load();
                    audioPlayer.current.audioEl.current.play();
                  }, 500);
                }}
              >
                <FontAwesomeIcon
                  style={{ width: "22px", height: "22px", marginLeft: "6px" }}
                  icon={faForward}
                />
              </div>

              <div
                className="sbvideoplay-leftBtnWrap"
                style={{}}
                onClick={() => {
                  setIsBgSound(!isBgSound);
                }}
              >
                <FontAwesomeIcon
                  style={{ width: "22px", height: "22px", marginLeft: "6px" }}
                  icon={isBgSound ? faVolumeHigh : faVolumeMute}
                />
              </div>

              <div className="sbvideoplay-left-scroll">
                {selectProgram?.pro_array?.map((item: any, idx: number) => (
                  <div
                    key={idx}
                    className="sbvideoplay-leftBtnWrap-play"
                    style={{
                      backgroundColor:
                        idx === playIndex ? "#163386" : "#000000",
                      // color: idx === playIndex ? "#000000" : "#ffffff",
                      color: "#ffffff",
                      paddingBottom: "5px",
                    }}
                    onClick={() => {
                      setCountVolumeLevel(0);
                      if (countPlayer) {
                        countPlayer.current.audioEl.current.currentTime = 0;
                        countPlayer.current.audioEl.current.pause();
                      }

                      let nextTime = EMPTY_REST_TIME;
                      mParams.current.playIndex = idx;
                      // console.log('\n\n\n\n\n\n************* idx : '+idx)
                      setPlayIndex(idx);
                      setIsPlayEx(true);
                      setIsLogoScreen(false);
                      setExTimer(nextTime);
                      setIsTimerVisible(true);
                      mParams.current.exTimer = nextTime;
                      mParams.current.exStatus = "r";
                      setPlayStatus("r");
                      setExTimer(nextTime);

                      timerHandler();
                    }}
                  >
                    {idx + 1}
                  </div>
                ))}
              </div>
              <div style={{ width: "1px", minHeight: "145px" }}></div>
            </div>

            <div className="sbvideoplay-right" style={{}}>
              {
                MyUtil._isNull(selectProgram?.pro_array) ? (
                  <p style={{ color: "#ffffff", fontSize: "1.2rem" }}>
                    Loading...
                  </p>
                ) : isLogoScreen ? (
                  <img
                    style={{ width: "200px", marginBottom: "150px" }}
                    src={ic_wait_logo}
                    alt="logo image"
                  />
                ) : // ************************************************** 8 분할 화면 **************************************************
                !MyUtil._isNull(tempVideo) ? (
                  <div
                    style={{
                      alignItems: "center",
                      justifyContent: "flex-start",
                      display: "flex",
                      position: "relative",
                      flex: 1,
                      flexDirection: "column",
                    }}
                  >
                    {/* <div style={{ alignItems: 'center', justifyContent: tempVideo?.length >= 4 ? 'flex-start' : 'center', display: 'flex', position: 'relative', flex: 1, flexDirection: 'column' }}> */}
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        width: tempVideo?.length === 4 ? "60%" : "100%",
                        marginTop: "0px",
                        flexWrap: "wrap",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      {tempVideo?.map((item: any, idx: number) => {
                        let videoSize = "23.3vw";
                        let videoMargin = {
                          marginLeft: "0.2vw",
                          marginRight: "0.3vw",
                        };
                        let videoMarginBottom = "2px";
                        let textareaWidth = "calc(51vw - 40px)"; // 기본 크기
                        let textareaHeight = "36px"; // 기본 크기
                        let fontSize = "8px"; // 기본 폰트 크기
                        let lineHeight = "12px";
                        let top = "10px";
                        let left = "40px";

                        if (tempVideo?.length >= 7) {
                          videoSize = "23.3vw";
                          videoMarginBottom = "10px";
                          textareaWidth = "calc(23.3vw - 25px)";
                          textareaHeight = "36px";
                          fontSize = "8px";
                          lineHeight = "12px";
                          top = "8px";
                          left = "25px";
                        } else if (tempVideo?.length >= 5) {
                          videoSize = "24.6vw";
                          videoMarginBottom = "10px";
                          textareaWidth = "calc(24.6vw - 30px)";
                          textareaHeight = "31px";
                          fontSize = "8px";
                          lineHeight = "10px";
                          top = "8px";
                          left = "25px";
                        } else if (tempVideo?.length >= 4) {
                          videoSize = "25vw";
                          videoMarginBottom = "10px";
                          textareaWidth = "calc(25vw - 25px)";
                          textareaHeight = "31px";
                          fontSize = "8px";
                          lineHeight = "10px";
                          top = "10px";
                          left = "25px";
                        } else if (tempVideo?.length >= 3) {
                          videoSize = "30vw";
                          videoMarginBottom = "10px";
                          textareaWidth = "calc(30vw - 27px)";
                          textareaHeight = "31px";
                          fontSize = "8px";
                          lineHeight = "10px";
                          top = "10px";
                          left = "25px";
                        } else if (tempVideo?.length >= 2) {
                          videoSize = "47vw";
                          videoMarginBottom = "10px";
                          textareaWidth = "calc(47vw - 25px)";
                          textareaHeight = "31px";
                          fontSize = "8px";
                          lineHeight = "10px";
                          top = "10px";
                          left = "25px";
                        } else if (tempVideo?.length >= 1) {
                          videoSize = "51vw";
                          videoMarginBottom = "10px";
                          textareaWidth = "calc(51vw - 25px)";
                          textareaHeight = "36px";
                          fontSize = "10px";
                          lineHeight = "12px";
                          top = "9px";
                          left = "25px";
                        }

                        return (
                          <div
                            key={idx}
                            style={{
                              marginTop: "8px",
                              marginBottom: videoMarginBottom,
                              position: "relative",
                            }}
                          >
                            <ReactPlayer
                              key={idx}
                              width={videoSize}
                              height={videoSize}
                              style={videoMargin}
                              url={item.mv_url}
                              playing={true}
                              // playing={(isPlayEx) ? true : false}
                              controls={false}
                              muted={true}
                              volume={0}
                              progressInterval={1000}
                              pip={false}
                              loop={true}
                            />

                            <p
                              style={{
                                color: "#000000",
                                fontSize: "16px",
                                fontWeight: "bold",
                                position: "absolute",
                                top: 10,
                                left: 10,
                              }}
                            >
                              {
                                item.seq_type
                                // idx === 0 ? 'A' : idx === 1 ? 'B' : idx === 2 ? 'C' : idx === 3 ? 'D' : idx === 4 ? 'E' : idx === 5 ? 'F' : idx === 6 ? 'G' : idx === 7 ? 'H' : ''
                              }
                            </p>
                            {rxLoginInfo.guide_yn !== "n" && item.mv_text && (
                              <StyledTextarea
                                readOnly
                                disabled
                                value={item.mv_text}
                                style={{
                                  width: textareaWidth,
                                  height: textareaHeight,
                                  fontSize: fontSize,
                                  lineHeight: lineHeight,
                                  top: top,
                                  left: left,
                                }}
                              />
                            )}
                          </div>
                        );
                      })}
                    </div>
                  </div>
                ) : (
                  <></>
                )

                // ************************************************** 옛날 1/2/3 분할 화면 **************************************************

                // <>
                //   <div style={{
                //     flexDirection: 'row', display: 'flex', width: '100%', height: '100%', overflow: 'hidden', justifyContent: 'center',
                //     paddingLeft: (MyUtil._isNull(selectProgram?.in_array[playIndex]?.mv_url2) && MyUtil._isNull(selectProgram?.in_array[playIndex]?.mv_url3)) ? '600px'
                //       : (!MyUtil._isNull(selectProgram?.in_array[playIndex]?.mv_url2) && !MyUtil._isNull(selectProgram?.in_array[playIndex]?.mv_url3)) ? '130px'
                //         : (!MyUtil._isNull(selectProgram?.in_array[playIndex]?.mv_url2) || !MyUtil._isNull(selectProgram?.in_array[playIndex]?.mv_url3)) ? '0px'
                //           : '0px'
                //   }}>
                //     <div style={{ display: 'flex', flexDirection: 'column', flex: 1, alignItems: 'center' }}>
                //       <ReactPlayer
                //         width={'calc(50vw - 32px)'}
                //         height={'calc(50vw - 32px)'}
                //         url={mvUrl1}
                //         playing={true}
                //         // playing={(isPlayEx) ? true : false}
                //         controls={false}
                //         muted={true}
                //         volume={0}
                //         progressInterval={1000}
                //         pip={false}
                //         loop={true}
                //       // controls={false}
                //       // muted={false}
                //       // progressInterval={1000}
                //       // pip={false}
                //       // loop={true}
                //       />
                //     </div>

                //     <div style={{ width: '10px', height: '1px', display: 'flex' }}></div>

                //     {
                //       (!MyUtil._isNull(selectProgram?.in_array[playIndex]?.mv_url2) && !MyUtil._isNull(selectProgram?.in_array[playIndex]?.mv_url3)) ? (
                //         <div style={{ display: 'flex', flexDirection: 'column', flex: 1, marginTop: '0px' }}>
                //           <ReactPlayer
                //             width={'41vh'}
                //             height={'41vh'}
                //             url={mvUrl2}
                //             playing={true}
                //             // playing={(isPlayEx) ? true : false}
                //             controls={false}
                //             muted={true}
                //             volume={0}
                //             progressInterval={1000}
                //             pip={false}
                //             loop={true}
                //           />

                //           <div style={{ width: '1px', height: '11px' }}></div>

                //           <ReactPlayer
                //             width={'41vh'}
                //             height={'41vh'}
                //             url={mvUrl3}
                //             playing={true}
                //             // playing={(isPlayEx) ? true : false}
                //             controls={false}
                //             muted={true}
                //             volume={0}
                //             progressInterval={1000}
                //             pip={false}
                //             loop={true}
                //           />
                //         </div>

                //       ) : MyUtil._isNull(selectProgram?.in_array[playIndex]?.mv_url2) ? (
                //         <div style={{ display: 'flex', flexDirection: 'column', flex: 1 }}>
                //           <MemoPlayer mvUrl={mvUrl3} />
                //         </div>

                //       ) : (

                //         <div style={{ display: 'flex', flexDirection: 'column', flex: 1 }}>
                //           <MemoPlayer mvUrl={mvUrl2} />
                //         </div>
                //       )
                //     }

                //   </div>
                // </>
              }
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default SBVideoPlay;

const StyledTextarea = styled.textarea`
  width: calc(100% - 30px);
  height: 30px;
  overflow: hidden;
  color: #000;
  background: none;
  border: none;
  font-size: 8px;
  font-weight: 500;
  position: absolute;
  top: 5px;
  left: 40px;
  line-height: 12px;
  backdrop-filter: blur(10px);
  resize: none;
`;

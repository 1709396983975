import {combineReducers} from 'redux';
import {signUpReducer} from "./signUpReducer";
import videoUrlsReducer from "./videoUrlsReducer";
import AsyncStorage from "@react-native-async-storage/async-storage";
import {TOGGLE_CALENDAR_MODAL, TOGGLE_ITEM_SELECTION} from "./allActions";

const rxLoginInfo = (state = {rxLoginInfo: null}, action: any) => {
    switch (action.type) {
        case "SET_USER":
            return {
                ...state,
                rxLoginInfo: action.payload
            }
        case "LOG_OUT":
            return {
                ...state,
                rxLoginInfo: null
            }
        default:
            return state
    }
};

export const loadLoginInfoFromAsyncStorage = async () => {
    try {
        const storedLoginInfo = await AsyncStorage.getItem('@login_info');
        console.log("Loaded login info from AsyncStorage:", storedLoginInfo);
        return storedLoginInfo ? JSON.parse(storedLoginInfo) : null;
    } catch (error) {
        console.error("Error loading login info from AsyncStorage:", error);
        return null;
    }
};

const MLoginInfo = (state = {MLoginInfo: null}, action: any) => {
    switch (action.type) {
        case "SET_USER":
            // AsyncStorage에 사용자 정보 저장
            AsyncStorage.setItem('@login_info', JSON.stringify(action.payload));
            return {
                ...state,
                MLoginInfo: action.payload
            };
        case "LOG_OUT":
            // AsyncStorage에서 사용자 정보 제거
            AsyncStorage.removeItem('@login_info');
            return {
                ...state,
                MLoginInfo: null
            };
        default:
            return state;
    }
};

const initialRememberIdState = {
    rememberIdEnabled: true
};

export const rememberIdReducer = (state = initialRememberIdState, action: any) => {
    switch (action.type) {
        case "SET_REMEMBER_ID_ENABLED":
            return {
                ...state,
                rememberIdEnabled: action.payload
            };
        default:
            return state;
    }
};

const initialAutoLoginState = {
    autoLoginEnabled: false
};

export const autoLoginReducer = (state = initialAutoLoginState, action: any) => {
    switch (action.type) {
        case "SET_AUTO_LOGIN_ENABLED":
            return {
                ...state,
                autoLoginEnabled: action.payload
            };
        default:
            return state;
    }
};

const initialState = {
    isVisible: false,
};

const calendarModalReducer = (state = initialState, action: any) => {
    switch (action.type) {
        case TOGGLE_CALENDAR_MODAL:
            return {
                ...state,
                isVisible: !state.isVisible
            };
        default:
            return state;
    }
};

const selectInitialState = {
    selectedItems: {}
};

const itemSelectionReducer = (state = selectInitialState, action: any) => {
    switch (action.type) {
        case TOGGLE_ITEM_SELECTION:
            const {configNo, isSelected} = action.payload;
            return {
                ...state,
                selectedItems: {
                    ...state.selectedItems,
                    [configNo]: isSelected
                }
            };
        default:
            return state;
    }
};


const rootReducer = combineReducers({
    rxLoginInfo,
    MLoginInfo,
    signUpData: signUpReducer,
    videoUrls: videoUrlsReducer,
    autoLogin: autoLoginReducer,
    rememberId: rememberIdReducer,
    calendarModal: calendarModalReducer,
    itemSelection: itemSelectionReducer
});

export default rootReducer;

export type RootState = ReturnType<typeof rootReducer>;

import React, { useCallback, useState, useEffect, useRef } from "react";
import Modal from "react-modal";
import { useSelector, useDispatch } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import * as MyUtil from "../constants/MyUtil";
import * as ServerApi from "../constants/ServerApi";
import allActions from "../component/redux/allActions";
import { STORAGE_L } from "../constants/Constants";
import CryptoJS from "crypto-js";
import AES from "crypto-js/aes";
import Utf8 from "crypto-js/enc-utf8";
import Loader from "../component/Loader";
import Logo from "../mobile-pages/Compornent/Logo";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAngleDoubleUp,
  faAngleDoubleDown,
} from "@fortawesome/free-solid-svg-icons";
import styled from "styled-components";

/*
일반결제
(서버) merchant_uid 생성 -> (클라) PG 결제 -> (서버) Webhook 캐치 -> (클라-서버) merchant_uid 검증 -> 완료

정기결제 https://portone.gitbook.io/docs/auth/guide-1/bill
(서버) merchant_uid 생성 -> (클라) PG 결제 customer_uid 포함 -> (클라) 서버로 PG 완료 알림 -> (서버) customer_uid 으로 REST API로 결제 처리 -> 완료

// https://github.com/iamport/iamport-manual/blob/master/%EB%B9%84%EC%9D%B8%EC%A6%9D%EA%B2%B0%EC%A0%9C/example/inicis-request-billing-key.md
// https://developers.portone.io/docs/ko/auth/guide-1/undefined
// 빌링키 발급이 성공하면 빌링키는 전달된 customer_uid 와 1:1 매칭되어 아임포트에 저장됩니다. 보안상의 이유로 서버는 빌링키에 직접 접근할 수 없기 때문에 customer_uid를 이용해서 재결제(POST /subscribe/payments/again) REST API를 다음과 같이 호출합니다.

*/

// (일반결제)
// m_mng_pay_i

// 요청
// - pay_type -- 결제 유형 p1 : 일반결제, p2:정기결제
// - center_no  -- 지점번호
// - amount -- 금액

// 응답
// -- order_no -- 주문번호

// (콜백함수)
// m_mng_pay_complete --콜백 함수

const SABPay = ({}: any) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [btnPress, setBtnPress] = useState<any>("0");
  const { rxLoginInfo } = useSelector(
    (state: any) => state.rxLoginInfo,
    (prev: any, next: any) => {
      return prev.rxLoginInfo === next.rxLoginInfo;
    }
  );
  const nowURL = window.location.href;
  const location = useLocation<any>();
  const [loading, setLoading] = useState<boolean>(false);
  const searchParams = new URLSearchParams(location.search);
  const imp_uid = searchParams.get("imp_uid");
  const merchant_uid: any = searchParams.get("merchant_uid");
  const imp_success = searchParams.get("imp_success");

  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [modalMessage, setModalMessage] = useState("");

  const [currentSnapPoint, setCurrentSnapPoint] = useState(0);
  const containerRefs = [
    useRef<HTMLDivElement>(null),
    useRef<HTMLDivElement>(null),
    useRef<HTMLDivElement>(null),
  ];
  const [isScrolling, setIsScrolling] = useState(false);

  const [scrollPosition, setScrollPosition] = useState(0);
  const [atBottom, setAtBottom] = useState(false);

  // 터치 스크롤
  const [touchStart, setTouchStart] = useState(0);
  const [touchEnd, setTouchEnd] = useState(0);

  // 터치 시작점을 설정
  const handleTouchStart = (e: any) => {
    setTouchStart(e.targetTouches[0].clientY);
    setTouchEnd(e.targetTouches[0].clientY); // 초기화
  };

  // 터치 이동 중 마지막 터치 위치를 업데이트
  const handleTouchMove = (e: any) => {
    setTouchEnd(e.targetTouches[0].clientY);
  };

  // 터치가 끝날 때 스냅 포인트로 스크롤
  const handleTouchEnd = () => {
    if (touchStart - touchEnd > 150) {
      // 위로 스와이프
      handleDownClick();
    }

    if (touchStart - touchEnd < -150) {
      // 아래로 스와이프
      handleUpClick();
    }
  };

  // 터치 스크롤 끝

  const openAlertModal = (message: any) => {
    setModalMessage(message);
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
    history.push({ pathname: "/SignUpCompleteB" });
  };

  const handleScroll = () => {
    const position = window.pageYOffset;
    const windowHeight = window.innerHeight;
    const totalHeight = document.body.offsetHeight;

    setScrollPosition(position);
    setAtBottom(position + windowHeight >= totalHeight);
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll, { passive: true });

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const scrollToSnapPoint = (index: number) => {
    if (isScrolling) return;

    setCurrentSnapPoint(index);
    const currentElement = containerRefs[index].current;

    if (currentElement) {
      const elementPosition = currentElement.offsetTop;
      const elementHeight = currentElement.clientHeight;

      const scrollToPosition =
        elementPosition + elementHeight / 3 - window.innerHeight / 4;

      window.scrollTo({
        top: scrollToPosition,
        behavior: "smooth",
      });
    }
  };

  const handleUpClick = () => {
    if (currentSnapPoint > 0) {
      scrollToSnapPoint(currentSnapPoint - 1);
    }
  };

  const handleDownClick = () => {
    if (currentSnapPoint < containerRefs.length - 1) {
      scrollToSnapPoint(currentSnapPoint + 1);
    }
  };

  // console.log('nowURL : ' + nowURL)
  // console.log('searchParams : ' + searchParams)
  // console.log('imp_uid : ' + imp_uid)
  // console.log('merchant_uid === order_no : ' + merchant_uid)
  // console.log('imp_success : ' + imp_success + "__" + typeof imp_success)

  useEffect(() => {
    if (imp_success === "true") {
      // ------------ 화면 새로고침으로 정기/일반 결제 확인이 안됨. 무조건 서버 태워서  merchant_uid (order_no) 올려주고 완료 처리.
      // ** 일반결제 : 포트원 서버에서 콜백이 서버로 날아감.
      // ** 정기결제 : 마찬가지로 콜백 서버에 떨어짐.
      m_mng_card_reg_complete(merchant_uid);
    }

    // ** 아임포트 JS 호출
    const jquery = document.createElement("script");
    jquery.src = "https://code.jquery.com/jquery-1.12.4.min.js";
    const iamport = document.createElement("script");
    iamport.src = "https://cdn.iamport.kr/js/iamport.payment-1.1.8.js";
    document.head.appendChild(jquery);
    document.head.appendChild(iamport);

    // MyUtil._sessionCheck(rxLoginInfo, history, dispatch, allActions, true);

    if (!MyUtil._isNullExceptZero(rxLoginInfo?.center_no)) {
      localStorage.setItem(STORAGE_L.TEMP_LOGIN, JSON.stringify(rxLoginInfo));
    } else {
      let logininfo: any = localStorage.getItem(STORAGE_L.TEMP_LOGIN);
      if (!MyUtil._isNull(logininfo)) {
        logininfo = JSON.parse(logininfo || "");
        dispatch(allActions.setRxLoginInfo(logininfo));
      }
      MyUtil._sessionCheck(logininfo, history, dispatch, allActions, true);
    }

    return () => {
      document.head.removeChild(jquery);
      document.head.removeChild(iamport);
    };
  }, []);

  const m_mng_card_reg_complete = async (getMuid: any) => {
    setLoading(true);
    let result: any = await ServerApi.m_mng_card_reg_complete({
      merchant_uid: getMuid + "",
    });
    const { isOk, data } = MyUtil._isResultSucces(
      "m_mng_card_reg_complete",
      result
    );
    if (isOk) {
      openAlertModal("결제가 완료 되었습니다. 다시 로그인을 해주세요!");
      localStorage.setItem(STORAGE_L.TEMP_LOGIN, "");
      dispatch(allActions.logOut());
      history.push({ pathname: "/MLogin" });
    } else {
      setLoading(false);
      console.log("m_mng_card_reg_complete", data);
      // MyUtil._alertMsg('m_mng_card_reg_complete', data);
      openAlertModal(`${data.msg}`);
    }
  };

  // https://github.com/iamport/iamport-manual/blob/master/%EB%B9%84%EC%9D%B8%EC%A6%9D%EA%B2%B0%EC%A0%9C/example/inicis-request-billing-key.md
  // 빌링키 발급이 성공하면 빌링키는 전달된 customer_uid 와 1:1 매칭되어 아임포트에 저장됩니다. 보안상의 이유로 서버는 빌링키에 직접 접근할 수 없기 때문에 customer_uid를 이용해서 재결제(POST /subscribe/payments/again) REST API를 다음과 같이 호출합니다.
  const onClickPayment = (
    getAmount: any,
    getName: any,
    getTel: any,
    getEmail: any,
    getTitle: any,
    getSub: any,
    isRecurring: boolean,
    getCuid: string,
    getMuid: string
  ) => {
    // MyUtil._sessionCheck(rxLoginInfo, history, dispatch, allActions, false);

    const { IMP }: any = window;

    // 그라비티 키
    IMP.init(process.env.REACT_APP_PTO_KEY);
    // IMP.init("imp34170941");
    let data: any = {};
    let isTest = false;
    let ptKeyName = { pay: "", recurring: "" };
    const payKey: any = process.env.REACT_APP_PTO_PAY;
    const recKey: any = process.env.REACT_APP_PTO_RECURRING;

    // -----

    // 이니시스 상점아이디 구분
    // 일반결제용 : MOIAPP9690 , 정기결제용 : MOIgravi37/!Rick5500

    // 포트원 --- 결제연동에서 실제 pg 키 발급받아야됨.
    if (isTest) {
      ptKeyName = {
        pay: "html5_inicis.INIpayTest",
        recurring: "html5_inicis.INIBillTst",
      };
    } else {
      ptKeyName = { pay: payKey, recurring: recKey };
    }

    // getAmount = 1000;

    if (isRecurring) {
      // console.log('getCuid : ' + getCuid)
      const decryptedText = decrypt(getCuid);
      // console.log('decryptedText : ' + decryptedText)
      // return

      data = {
        pg: ptKeyName.recurring,
        pay_method: "card",
        merchant_uid: getMuid,
        name: getTitle,
        amount: getAmount,
        custom_data: { name: getTitle, desc: getSub },
        buyer_name: getName,
        buyer_tel: getTel,
        buyer_email: getEmail,
        buyer_addr: "",
        customer_uid: decryptedText, // @ 정기결제용 @ //
        buyer_postalcode: "", // ....
        m_redirect_url: nowURL, // 예: https://www.my-service.com/payments/complete/mobile
      };
    } else {
      data = {
        pg: ptKeyName.pay,
        pay_method: "card",
        merchant_uid: getMuid,
        name: getTitle,
        amount: getAmount,
        custom_data: { name: getTitle, desc: getSub },
        buyer_name: getName,
        buyer_tel: getTel,
        buyer_email: getEmail,
        buyer_addr: "",
        buyer_postalcode: "", // ....
        m_redirect_url: nowURL, // 예: https://www.my-service.com/payments/complete/mobile
      };
    }
    setBtnPress("0");
    IMP.request_pay(data, callback); // 콜백은 호출 안됨
  };

  const callback = (response: any) => {
    const {
      success,
      error_msg,
      imp_uid,
      merchant_uid,
      pay_method,
      paid_amount,
      error_code,
    } = response;
    if (success) {
      m_mng_card_reg_complete(merchant_uid);
    } else {
      openAlertModal(`${error_msg}`);
    }
  };

  const m_mng_pay_i = useCallback(
    async (
      getAmount: any,
      getName: any,
      getTel: any,
      getEmail: any,
      getTitle: any,
      getSub: any,
      isRecurring: boolean,
      getUid: string
    ) => {
      let result: any = await ServerApi.m_mng_pay_i({
        center_no: getUid,
        pay_type: isRecurring ? "p2" : "p1",
        amount: getAmount,
      });

      const { isOk, data } = MyUtil._isResultSucces("m_mng_pay_i", result);
      if (isOk) {
        // console.log('data.order_no : '+ JSON.stringify(data)) // 2023071900012
        onClickPayment(
          getAmount,
          getName,
          getTel,
          getEmail,
          getTitle,
          getSub,
          isRecurring,
          data.customer_u_id,
          data.order_no
        );
      } else {
        MyUtil._alertMsg("m_mng_pay_i", data);
      }
    },
    [rxLoginInfo]
  );

  const decrypt = (encryptedText: string) => {
    // import javax.crypto.Cipher;
    // import javax.crypto.spec.IvParameterSpec;
    // import javax.crypto.spec.SecretKeySpec;
    // import java.util.Base64;
    // public class AES256 {
    //     private static final String instanceType = "AES/CBC/PKCS5Padding";
    //     private static final String secretKey = "12345678901234567890123456789012"; // 32bit
    //     private static final String iv = secretKey.substring(0, 16); // 16bit
    //     public static String encrypt(String text) throws Exception {
    //         Cipher cipher = Cipher.getInstance(instanceType);
    //         SecretKeySpec secretKeySpec = new SecretKeySpec(secretKey.getBytes("UTF-8"), "AES");
    //         IvParameterSpec ivParameterSpec = new IvParameterSpec(iv.getBytes("UTF-8"));
    //         cipher.init(Cipher.ENCRYPT_MODE, secretKeySpec, ivParameterSpec);
    //         byte[] encrypted = cipher.doFinal(text.getBytes("UTF-8"));
    //         return new String(Base64.getEncoder().encode(encrypted));
    //     }
    // }

    const secretKey = "12345678901234567890123456789012"; // This should be same as Java secretKey
    const iv = secretKey.substring(0, 16);

    const decrypted = AES.decrypt(encryptedText, Utf8.parse(secretKey), {
      iv: Utf8.parse(iv),
      mode: CryptoJS.mode.CBC,
      padding: CryptoJS.pad.Pkcs7,
    });

    return decrypted.toString(Utf8);
  };

  return loading ? (
    <div className="container">
      <Loader />
    </div>
  ) : (
    <div
      style={{ position: "relative" }}
      onTouchStart={handleTouchStart}
      onTouchMove={handleTouchMove}
      onTouchEnd={handleTouchEnd}
    >
      {scrollPosition > 100 && (
        <button
          style={{
            position: "fixed",
            top: "10px",
            right: "50%",
            transform: "translate(50%)",
            zIndex: 1000,
            background: "none",
            border: "none",
            color: "rgba(0,0,0,0.3)",
            cursor: "pointer",
            fontSize: "1.2rem",
          }}
          onClick={handleUpClick}
        >
          <FontAwesomeIcon icon={faAngleDoubleUp} />
        </button>
      )}
      {!atBottom && (
        <button
          style={{
            position: "fixed",
            bottom: "10px",
            right: "50%",
            transform: "translate(50%)",
            zIndex: 1000,
            background: "none",
            border: "none",
            color: "rgba(0,0,0,0.3)",
            cursor: "pointer",
            fontSize: "1.2rem",
          }}
          onClick={handleDownClick}
        >
          <FontAwesomeIcon icon={faAngleDoubleDown} />
        </button>
      )}
      <div className="container-wrap" style={{ marginTop: "70px" }}>
        <div ref={containerRefs[0]} className={"pay-wrap b-width-100"}>
          {currentSnapPoint === 0 && (
            <PayHeaderContainer>
              <Logo onChangeMode={() => {}} logoType={1} />
            </PayHeaderContainer>
          )}
          <span className="pay-title">Personal</span>
          <span className="pay-price">99,000원</span>

          <span className="pay-tSub" style={{ marginTop: "20px" }}>
            사용자당 매월, 정기구독시 89,000원
          </span>
          <span className="pay-tSub" style={{ marginTop: "5px" }}>
            1년약정, 정기구독 해지 시 월이용금액 적용
          </span>

          <span className="pay-tSubL" style={{ marginTop: "35px" }}>
            # 개인이용자 또는 엔컴파스 2대 이하
            <br />
            사업장에서 이용가능한 요금제,
            <br />
            운동시간, 휴식시간, 운동프로그램
            <br />
            가져오기 등 모든 기능
          </span>

          <span className="pay-tSubCheck" style={{ marginTop: "25px" }}>
            <b>✔</b>&nbsp; Starter 기능 포함
          </span>
          <span className="pay-tSubCheck" style={{ marginTop: "10px" }}>
            <b>✔</b>&nbsp; 운동시간, 휴식시간 설정
          </span>
          <span className="pay-tSubCheck" style={{ marginTop: "10px" }}>
            <b>✔</b>&nbsp; 운동프로그램 생성
          </span>
          <span className="pay-tSubCheck" style={{ marginTop: "10px" }}>
            <b>✔</b>&nbsp; 시퀀스 생성
          </span>
          <span className="pay-tSubCheck" style={{ marginTop: "10px" }}>
            <b>✔</b>&nbsp; 운동 동작별 동작가이드 기록
          </span>
          <span className="pay-tSubCheck" style={{ marginTop: "10px" }}>
            <b>✔</b>&nbsp; 타 지점 운동프로그램 사용
          </span>
          <span className="pay-tSubCheck" style={{ marginTop: "10px" }}>
            <b>✔</b>&nbsp; 8개 화면 분할
          </span>
          <span className="pay-tSubCheck" style={{ marginTop: "10px" }}>
            <b>✔</b>&nbsp; 폴더 생성
          </span>

          <div className="pay-btnWrap">
            <div
              className="pay-btn"
              style={{
                backgroundColor: btnPress === "1" ? "#000" : "#09348a",
                border: "none",
              }}
              onClick={() => {
                setBtnPress("1");
                setTimeout(() => {
                  m_mng_pay_i(
                    "99000",
                    rxLoginInfo.name,
                    rxLoginInfo.handphone,
                    rxLoginInfo.userid,
                    "Personal",
                    "Personal 월간결제",
                    false,
                    rxLoginInfo.center_no
                  );
                }, 500);
              }}
            >
              <span
                className="pay-btnTxt"
                style={{ color: btnPress === "1" ? "#09348a" : "#fff" }}
              >
                월간결제
              </span>
            </div>
            <div
              className="pay-btn"
              style={{
                backgroundColor: btnPress === "2" ? "#000" : "#09348a",
                border: "none",
              }}
              onClick={() => {
                setBtnPress("2");
                setTimeout(() => {
                  m_mng_pay_i(
                    "89000",
                    rxLoginInfo.name,
                    rxLoginInfo.handphone,
                    rxLoginInfo.userid,
                    "Personal",
                    "Personal 정기구독",
                    true,
                    rxLoginInfo.center_no
                  );
                }, 500);
              }}
            >
              <span
                className="pay-btnTxt"
                style={{ color: btnPress === "2" ? "#09348a" : "#fff" }}
              >
                정기구독
              </span>
            </div>
          </div>
        </div>

        <div ref={containerRefs[1]} className={"pay-wrap b-width-100"}>
          <span className="pay-title">Business Starter</span>
          <span className="pay-price">395,000원</span>

          <span className="pay-tSub" style={{ marginTop: "20px" }}>
            사용자당 매월, 정기구독시 295,000원
          </span>
          <span className="pay-tSub" style={{ marginTop: "5px" }}>
            1년약정, 정기구독 해지 시 월이용금액 적용
          </span>

          <span className="pay-tSubL" style={{ marginTop: "35px" }}>
            # 운동시간, 휴식시간, 운동프로그램
            <br />
            가져오기 등 모든 기능이 사용가능한 요금제
          </span>

          <span className="pay-tSubCheck" style={{ marginTop: "25px" }}>
            <b>✔</b>&nbsp; Starter 기능 포함
          </span>
          <span className="pay-tSubCheck" style={{ marginTop: "10px" }}>
            <b>✔</b>&nbsp; 운동시간, 휴식시간 설정
          </span>
          <span className="pay-tSubCheck" style={{ marginTop: "10px" }}>
            <b>✔</b>&nbsp; 운동프로그램 생성
          </span>
          <span className="pay-tSubCheck" style={{ marginTop: "10px" }}>
            <b>✔</b>&nbsp; 시퀀스 생성
          </span>
          <span className="pay-tSubCheck" style={{ marginTop: "10px" }}>
            <b>✔</b>&nbsp; 운동 동작별 동작가이드 기록
          </span>
          <span className="pay-tSubCheck" style={{ marginTop: "10px" }}>
            <b>✔</b>&nbsp; 타 지점 운동프로그램 사용
          </span>
          <span className="pay-tSubCheck" style={{ marginTop: "10px" }}>
            <b>✔</b>&nbsp; 8개 화면 분할
          </span>
          <span className="pay-tSubCheck" style={{ marginTop: "10px" }}>
            <b>✔</b>&nbsp; 폴더 생성
          </span>

          <div className="pay-btnWrap">
            <div
              className="pay-btn"
              style={{
                backgroundColor: btnPress === "3" ? "#000" : "#09348a",
                border: "none",
              }}
              onClick={() => {
                setBtnPress("3");
                setTimeout(() => {
                  m_mng_pay_i(
                    "395000",
                    rxLoginInfo.name,
                    rxLoginInfo.handphone,
                    rxLoginInfo.userid,
                    "Business Starter",
                    "Business Starter 월간결제",
                    false,
                    rxLoginInfo.center_no
                  );
                }, 500);
              }}
            >
              <span
                className="pay-btnTxt"
                style={{ color: btnPress === "3" ? "#09348a" : "#fff" }}
              >
                월간결제
              </span>
            </div>
            <div
              className="pay-btn"
              style={{
                backgroundColor: btnPress === "4" ? "#000" : "#09348a",
                border: "none",
              }}
              onClick={() => {
                setBtnPress("4");
                setTimeout(() => {
                  m_mng_pay_i(
                    "295000",
                    rxLoginInfo.name,
                    rxLoginInfo.handphone,
                    rxLoginInfo.userid,
                    "Business Starter",
                    "Business Starter 정기구독",
                    true,
                    rxLoginInfo.center_no
                  );
                }, 500);
              }}
            >
              <span
                className="pay-btnTxt"
                style={{ color: btnPress === "4" ? "#09348a" : "#fff" }}
              >
                정기구독
              </span>
            </div>
          </div>
        </div>

        <div
          ref={containerRefs[2]}
          className={"pay-wrap b-width-100"}
          style={{ marginBottom: "60px" }}
        >
          <span className="pay-title">Business Plus</span>
          <span className="pay-price">690,000원</span>

          <span className="pay-tSub" style={{ marginTop: "20px" }}>
            사용자당 매월, 정기구독시 590,000원
          </span>
          <span className="pay-tSub" style={{ marginTop: "5px" }}>
            1년약정, 정기구독 해지 시 월이용금액 적용
          </span>

          <span className="pay-tSubL" style={{ marginTop: "35px" }}>
            # 운동시간, 휴식시간, 운동프로그램
            <br />
            가져오기 등 모든 기능이 사용가능한 요금제
          </span>

          <span className="pay-tSubCheck" style={{ marginTop: "25px" }}>
            <b>✔</b>&nbsp; Starter 기능 포함
          </span>
          <span className="pay-tSubCheck" style={{ marginTop: "10px" }}>
            <b>✔</b>&nbsp; 운동시간, 휴식시간 설정
          </span>
          <span className="pay-tSubCheck" style={{ marginTop: "10px" }}>
            <b>✔</b>&nbsp; 운동프로그램 생성
          </span>
          <span className="pay-tSubCheck" style={{ marginTop: "10px" }}>
            <b>✔</b>&nbsp; 시퀀스 생성
          </span>
          <span className="pay-tSubCheck" style={{ marginTop: "10px" }}>
            <b>✔</b>&nbsp; 운동 동작별 동작가이드 기록
          </span>
          <span className="pay-tSubCheck" style={{ marginTop: "10px" }}>
            <b>✔</b>&nbsp; 타 지점 운동프로그램 사용
          </span>
          <span className="pay-tSubCheck" style={{ marginTop: "10px" }}>
            <b>✔</b>&nbsp; 8개 화면 분할
          </span>
          <span className="pay-tSubCheck" style={{ marginTop: "10px" }}>
            <b>✔</b>&nbsp; 폴더 생성
          </span>

          <div className="pay-btnWrap">
            <div
              className="pay-btn"
              style={{
                backgroundColor: btnPress === "5" ? "#000" : "#09348a",
                border: "none",
              }}
              onClick={() => {
                setBtnPress("5");
                setTimeout(() => {
                  m_mng_pay_i(
                    "690000",
                    rxLoginInfo.name,
                    rxLoginInfo.handphone,
                    rxLoginInfo.userid,
                    "Business Plus",
                    "Business Plus 월간결제",
                    false,
                    rxLoginInfo.center_no
                  );
                }, 500);
              }}
            >
              <span
                className="pay-btnTxt"
                style={{ color: btnPress === "5" ? "#09348a" : "#fff" }}
              >
                월간결제
              </span>
            </div>
            <div
              className="pay-btn"
              style={{
                backgroundColor: btnPress === "6" ? "#000" : "#09348a",
                border: "none",
              }}
              onClick={() => {
                setBtnPress("6");
                setTimeout(() => {
                  m_mng_pay_i(
                    "590000",
                    rxLoginInfo.name,
                    rxLoginInfo.handphone,
                    rxLoginInfo.userid,
                    "Business Plus",
                    "Business Plus 정기구독",
                    true,
                    rxLoginInfo.center_no
                  );
                }, 500);
              }}
            >
              <span
                className="pay-btnTxt"
                style={{ color: btnPress === "6" ? "#09348a" : "#fff" }}
              >
                정기구독
              </span>
            </div>
          </div>
        </div>
      </div>
      <PayBottomContainer>
        <Logo onChangeMode={() => {}} logoType={1} />
      </PayBottomContainer>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        contentLabel="Alert Modal"
        className="modalAlertBox"
        style={{
          overlay: {
            backgroundColor: "rgba(0, 0, 0, 0.75)",
          },
        }}
      >
        <h2>{modalMessage}</h2>
        <button onClick={closeModal}>OK</button>
      </Modal>
    </div>
  );
};

const PayHeaderContainer = styled.div`
  width: 30%;
  position: fixed;
  top: 17px;
  left: 10px;
  z-index: 99;
`;

const PayBottomContainer = styled.div`
  width: 30%;
  position: absolute;
  bottom: 20px;
  right: 0;
`;

export default SABPay;

import React, { useState, useEffect, FormEvent } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory, useLocation } from "react-router-dom";
import { RootState } from "../component/redux/rootReducer";
import * as ServerApi from "../constants/ServerApiM";
import { JoinFormProps } from "./Interface/Interface";
import AlertModal from "react-modal";
import loginBtn from "../img/ic_arrow_rt_y.png";
import LoginButton from "./Compornent/LoginButton";
import styled, { css } from "styled-components";
import allActions from "../component/redux/allActions";
import backGround from "../img/new3.png";
import NewButtons from "./Compornent/NewButtons";
import { FaCheck, FaTimes } from "react-icons/fa";

type LocationState = {
  member_type?: "m1" | "m2";
};

const MobileContainer = styled.div`
  position: relative;
  width: 100%;
  height: 100vh;
  overflow: hidden;
`;

const BackGround = styled.div`
  height: 100%;
  background-image: linear-gradient(
      to bottom,
      rgba(0, 0, 0, 0.2),
      rgba(0, 0, 0, 0.9)
    ),
    url(${backGround});
  background-size: cover;
  background-position: calc(50% + 120px) center;
  z-index: 9;
  overflow: hidden;

  @media screen and (max-width: 400px) {
    background-position: center;
  }
`;

const AgreeContainer = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: absolute;
  bottom: 45%;
  left: 50%;
  transform: translate(-50%, 50%);
`;

const CheckBoxContainer = styled.div`
  position: relative;
  width: 90%;
  display: flex;
  flex-direction: column;
  text-align: left;
  margin: 0 auto;
  font-size: 0.9rem;
  font-weight: 500;
  color: #ccc;
  background: rgba(49, 54, 84, 0.3);
  backdrop-filter: blur(6px);
  padding: 15px 20px;
  border-radius: 15px;

  input[type="checkbox"] + label > span {
    vertical-align: middle;
    padding-left: 10px;
    line-height: 1.8rem;
  }

  input[type="checkbox"] {
    display: none;
  }

  input[type="checkbox"] + label:before {
    content: "";
    display: inline-block;
    width: 15px;
    height: 15px;
    border: 1px solid #ddd;
    border-radius: 4px;
    vertical-align: middle;
  }

  input[type="checkbox"]:checked + label:before {
    content: "";
    background-color: #ddd;
    border-color: #ddd;
  }
`;

const AllAgree = styled.div`
  margin-bottom: 20px;

  &::after {
    content: "";
    width: calc(100% - 20px);
    border-bottom: 1px solid #fff;
    position: absolute;
    top: 60px;
    left: 50%;
    transform: translate(-50%, 50%);
  }
`;

const CheckBoxWrap = styled.div`
  margin: 5px 0;
`;

const Message = styled.p`
  width: 90%;
  margin: 20px auto 0 auto;
  text-align: left;
  font-family: Suit, serif;
  font-size: 0.8rem;
  line-height: 1.4rem;
  color: rgba(242, 242, 242, 0.5);
`;

const NextButton = styled.div`
  width: 100%;
  height: 50px;
  display: flex;
  justify-content: center;
  position: absolute;
  bottom: 20%;
  left: 50%;
  transform: translate(-50%, 50%);

  //@media screen and (max-width: 400px) {
  //    bottom: 10%;
  //}
`;

const CButtons = styled.button`
  display: inline-flex;
  align-items: center;
  justify-content: space-around;
  padding: 0 20px;
  height: 50px;
  min-width: 150px;
  border: none;
  border-radius: 30px;
  cursor: pointer;
  outline: none;
  font-size: 16px;
  position: relative;

  &:first-child {
    margin-right: 10px;
  }
`;

const DButtons = styled.button`
  display: inline-flex;
  align-items: center;
  justify-content: space-around;
  padding: 0 20px;
  height: 50px;
  min-width: 150px;
  border: none;
  border-radius: 30px;
  cursor: pointer;
  outline: none;
  font-size: 16px;
  position: relative;
  background-color: #09348a;
  color: #eeeeee;

  &:first-child {
    margin-right: 10px;
  }

  &:disabled {
    background-color: #aaaaaa;
  }
`;

const CStatusCircle = styled.div`
  width: 46px;
  height: 46px;
  border-radius: 50%;
  background-color: #09348a;
  color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: -21px;
  left: 26px;
  transform: translate(-50%, 50%);
`;

const DStatusCircle = styled.div`
  width: 46px;
  height: 46px;
  border-radius: 50%;
  background-color: #eee;
  color: #09348a;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: -21px;
  left: 26px;
  transform: translate(-50%, 50%);
`;

const ButtonText = styled.div`
  font-size: 14px;
  font-weight: 500;
  margin-left: 30px;
  color: #000;
`;

const TermsAgreeTitle = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 10%;
  left: 50%;
  transform: translate(-50%, 50%);

  @media screen and (max-width: 400px) {
    top: -5%;
  }

  h2 {
    color: #f2f2f2;
    font-size: 1rem;
    font-weight: 700;
    margin: 15px auto 10px auto;
    font-family: Suit, serif;
  }

  p {
    color: #f2f2f2;
    font-size: 0.8rem;
    font-weight: 400;
    font-family: Suit, serif;
    line-height: 1.2rem;
  }
`;

const Title = styled.div`
  width: 100%;
  text-align: center;
  color: #fff;
  font-family: Poppins-Italic, serif;
  font-size: 2.2rem;
  line-height: 3rem;
  font-weight: 600;
`;

const NewButtonStyle = styled.div`
  margin-top: 30px;
`;

function TermsAgree({ linksToShow }: JoinFormProps) {
  const history = useHistory();
  const dispatch = useDispatch();
  const MLoginInfo = useSelector((state: RootState) => state.MLoginInfo);
  const { rxLoginInfo } = useSelector(
    (state: RootState) => state.rxLoginInfo,
    (prev, next) => {
      return prev.rxLoginInfo === next.rxLoginInfo;
    }
  );
  const location = useLocation<LocationState>();
  const signUpData = useSelector((state: RootState) => state.signUpData);
  const memberType = location.state?.member_type;
  const type = memberType === "m1" ? "B" : "T";
  const [dnStatus, setDnStatus] = useState<
    "ready" | "loading" | "error" | "success"
  >("ready");

  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [modalMessage, setModalMessage] = useState("");

  const openAlertModal = (message: any) => {
    setModalMessage(message);
    setModalIsOpen(true);
  };

  const closeAlertModal = () => {
    setModalIsOpen(false);
  };

  const initialChecklist = {
    agree1: false,
    agree2: false,
    agree3: false,
    agreeAll: false,
  };

  const [checklist, setChecklist] = useState(initialChecklist);

  useEffect(() => {
    if (checklist.agree1 && checklist.agree2 && checklist.agree3) {
      setChecklist((prevChecklist) => ({
        ...prevChecklist,
        agreeAll: true,
      }));
    } else {
      setChecklist((prevChecklist) => ({
        ...prevChecklist,
        agreeAll: false,
      }));
    }
  }, [checklist.agree1, checklist.agree2, checklist.agree3]);

  const handleCheckboxChange = (event: any) => {
    const { id, checked } = event.target;
    setChecklist({ ...checklist, [id]: checked });
  };

  const toggleAllCheckboxes = () => {
    const newAgreeAllValue = !checklist.agreeAll;
    const allAgreed = checklist.agree1 && checklist.agree2 && checklist.agree3;
    setChecklist({
      agree1: newAgreeAllValue,
      agree2: newAgreeAllValue,
      agree3: newAgreeAllValue,
      agreeAll: allAgreed,
    });
  };

  const handleButtonClick = async (e: FormEvent) => {
    if (e) {
      e.preventDefault();
    }
    if (checklist.agreeAll && signUpData) {
      if (memberType === "m1") {
        // 비즈니스 - 회원가입 데이터를 서버로 전송 후, 회원가입 완료 페이지로 이동
        setDnStatus("loading");
        try {
          const response = await ServerApi.m_app_join(signUpData);
          console.log("회원가입 응답", response);
          if (response.rsp_code === "100") {
            dispatch(allActions.setMLoginInfo(signUpData));
            dispatch(allActions.setRxLoginInfo(signUpData));
            setTimeout(() => {
              setDnStatus("success");
              setTimeout(() => {
                // history.push("/SABPay", {
                //   member_type: memberType,
                //   signUpData: signUpData,
                //   MLoginInfo: MLoginInfo,
                //   rxLoginInfo: rxLoginInfo,
                // });
                history.push("/SignUpCompleteB", {
                  member_type: memberType,
                  signUpData: signUpData,
                });
              }, 1000);
            }, 1000);
          } else {
            openAlertModal("회원가입에 실패하였습니다.");
            history.push({
              pathname: "/JoinPageB",
              state: { memberType: "m1" },
            });
          }
        } catch (error) {
          console.error("회원가입 오류:", error);
          openAlertModal("회원가입에 실패하였습니다.");
          history.push({
            pathname: "/JoinPageB",
            state: { memberType: "m1" },
          });
        }
      } else if (memberType === "m2") {
        try {
          const response = await ServerApi.m_app_join(signUpData);
          if (response.rsp_code === "100") {
            history.push("/SignUpCompleteT", {
              member_type: memberType,
              signUpData: signUpData,
            });
          } else {
            openAlertModal("회원가입에 실패하였습니다.");
            history.push({
              pathname: "/JoinPageT",
              state: { memberType: "m2" },
            });
          }
        } catch (error) {
          console.error("회원가입 오류:", error);
          openAlertModal("회원가입에 실패하였습니다.");
          history.push({
            pathname: "/JoinPageT",
            state: { memberType: "m2" },
          });
        }
      }
    } else {
      openAlertModal("모든 약관에 동의해주세요.");
    }
  };

  const renderTitle = () => {
    return (
      <TermsAgreeTitle>
        {type === "T" ? <Title>Trainer</Title> : <Title>Business</Title>}
        <h2>계속 진행하시려면 아래 권한에 동의해주세요.</h2>
        <p>그래비티 계정가입을 환영합니다!</p>
        <p>법률 약관이 업데이트 되었습니다.</p>
        <p>진행하시기 전에 내용을 검토하고 동의해 주세요.</p>
      </TermsAgreeTitle>
    );
  };

  const renderButton = () => {
    return (
      <>
        {type === "T" ? (
          <NextButton>
            <CButtons type="reset" onClick={handleButtonClick}>
              <CStatusCircle>
                <FaTimes />
              </CStatusCircle>
              <Link to="/">
                <ButtonText>Cancel</ButtonText>
              </Link>
            </CButtons>
            {checklist.agreeAll ? (
              <DButtons type="button" onClick={handleButtonClick}>
                <DStatusCircle>
                  <FaCheck />
                </DStatusCircle>
                <ButtonText>Done</ButtonText>
              </DButtons>
            ) : (
              <DButtons type="button" disabled>
                <DStatusCircle>
                  <FaCheck style={{ color: "#999" }} />
                </DStatusCircle>
                <ButtonText>Done</ButtonText>
              </DButtons>
            )}
          </NextButton>
        ) : (
          <NewButtonStyle>
            <NewButtons
              text="Continue"
              dnStatus={dnStatus}
              myClick={handleButtonClick}
              backGround="white"
            />
          </NewButtonStyle>
        )}
      </>
    );
  };

  return (
    <MobileContainer>
      <BackGround />
      {renderTitle()}
      <AgreeContainer>
        <form onSubmit={(e) => e.preventDefault()}>
          <CheckBoxContainer>
            <AllAgree>
              <input
                type="checkbox"
                id="agreeAll"
                checked={checklist.agreeAll}
                onChange={toggleAllCheckboxes}
              />
              <label htmlFor="agreeAll">
                <span>모든 이용약관에 동의합니다.</span>
              </label>
            </AllAgree>
            <CheckBoxWrap>
              <input
                type="checkbox"
                id="agree1"
                checked={checklist.agree1}
                onChange={handleCheckboxChange}
              />
              <label htmlFor="agree1">
                <span>이용약관에 동의합니다.</span>
              </label>
            </CheckBoxWrap>
            <CheckBoxWrap>
              <input
                type="checkbox"
                id="agree2"
                checked={checklist.agree2}
                onChange={handleCheckboxChange}
              />
              <label htmlFor="agree2">
                <span>개인 정보 수집 및 이용에 동의합니다.</span>
              </label>
            </CheckBoxWrap>
            <CheckBoxWrap>
              <input
                type="checkbox"
                id="agree3"
                checked={checklist.agree3}
                onChange={handleCheckboxChange}
              />
              <label htmlFor="agree3">
                <span>개인 정보의 제3자 제공 및 국외 이전에 동의합니다.</span>
              </label>
            </CheckBoxWrap>
          </CheckBoxContainer>
          <Message>
            약관에 동의하지 않을 수 있지만, 그래비티 계정에 로그인 할 수 없으며
            새로운 계정을 생성할 수 없습니다.
          </Message>
          {renderButton()}
        </form>
      </AgreeContainer>
      <AlertModal
        isOpen={modalIsOpen}
        onRequestClose={closeAlertModal}
        contentLabel="Alert Modal"
        className="modalAlertBox"
        style={{
          overlay: {
            backgroundColor: "rgba(0, 0, 0, 0.6)",
            zIndex: "99",
          },
        }}
      >
        <h2>{modalMessage}</h2>
        <button onClick={closeAlertModal}>Close</button>
      </AlertModal>
    </MobileContainer>
  );
}

export default TermsAgree;

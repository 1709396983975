import React, { ComponentType, useEffect, useRef, useState } from "react";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  useHistory,
} from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import allActions from "../component/redux/allActions";
import {
  loadLoginInfoFromAsyncStorage,
  RootState,
} from "../component/redux/rootReducer";

// ------ 모바일 ------ //
// import Home from "./Home";
import Home from "./NewHome";
// import MLogin from "./MLogin";
import MLogin from "./NewLogin";
// import BusinessHome from "./BusinessHome";
import BusinessHome from "./NewBusinessHome";
// import TrainerHome from "./TrainerHome";
import TrainerHome from "./NewTrainerHome";
// import JoinPageB from "./JoinPageB";
import JoinPageB from "./NewJoinPageB";
// import JoinPageT from "./JoinPageT";
import JoinPageT from "./NewJoinPageT";
import RatePlan from "./RatePlan";
import { SignUpCompleteT, SignUpCompleteB } from "./NewSignUpComplete";
import MyAccount from "./MyAccount";
import ProgramsItemM from "./ProgramsItemM";
import DayProgramM from "./DayProgramM";
import ProgramsVideoM from "./ProgramsVideoM";
import DayProgramTutorialM from "./DayProgramTutorialM";
import TimeSetupM from "./TimeSetupM";
import MVideoPlay from "./MVideoPlay";
import MyAccountB from "./MyAccountB";
import MyAccountB2 from "./MyAccountB2";
import AccountSetup from "./AccountSetup";
// import TermsAgree from "./TermsAgree";
import TermsAgree from "./NewTermsAgree";
import ProgramsVideoM2 from "./ProgramsVideoM2";

import withSessionCheck from "./Interface/WithSessionCheck";
import BackButton, { LoginBackButton } from "./Compornent/BackButton";
import SBVideoPlay from "../screens-branch/SBVideoPlay";
import VideoPlayM from "../mobile-pages/Compornent/VideoPlayM";
import SABPayList from "../screens-branch/SABPayList";
import SABPay from "../screens-branch/SABPay";
import MusicSetup from "./MusicSetupM";
import MusicSetupM from "./MusicSetupM";
import VideoPlayerPage from "./Compornent/VideoPlayerPage";
// import ForgotPassword from "./ForgotPassword";
import ForgotPassword from "./NewForgotPassword";
import ForgotSuccess from "./ForgotSuccess";
// import DeniedLogin from './DeniedLogin';
import DeniedLogin from "./NewDeniedLogin";
// import VideoPlay from "./Compornent/NewVideoPlay";
// import VideoPlay from "./MNewVideoPlay";
// import VideoPlay from "./NewVideoPlayer";
import VideoPlay from "./OldVideoPlay";
import NumberPad from "./NumberPad";

const CheckedMyAccount = withSessionCheck(MyAccount);
const CheckedAccountSetup = withSessionCheck(AccountSetup);
const CheckedProgramsVideo = withSessionCheck(ProgramsVideoM2);
const CheckedDayProgram = withSessionCheck(DayProgramM);

interface WithBackButtonProps {
  Component: ComponentType<any>;
  isLogIn?: any;
  disableLogout?: any;
  whiteLogout?: any;
  showBackButton?: any;
  showWhiteBackButton?: any;
}

declare global {
  interface Window {
    ReactNativeWebView: {
      postMessage(message: string): void;
    };
  }
}

const withBackButton =
  ({
    Component,
    isLogIn,
    disableLogout,
    whiteLogout,
    showBackButton,
    showWhiteBackButton,
  }: WithBackButtonProps) =>
  (props: any) =>
    (
      <>
        <BackButton
          isLogIn={isLogIn}
          disableLogout={disableLogout}
          showBackButton={showBackButton}
          showWhiteBackButton={showWhiteBackButton}
          whiteLogout={whiteLogout}
        />
        <Component {...props} />
      </>
    );

const MobileRouter = () => {
  const [isLogIn, setIsLogIn] = useState(false);
  const [initialRoute, setInitialRoute] = useState("/");
  const mLoginInfo = useSelector(
    (state: RootState) => state.MLoginInfo.MLoginInfo
  );
  const dispatch = useDispatch();
  const state = useSelector((state) => state);
  console.log("state", state);

  // iOS 체크
  const isIOS = /iPad|iPhone|iPod/.test(navigator.userAgent);

  const MLoginWithCustomBack = () => {
    const plusFunc = () => {
      setInitialRoute("/");
    };
    return (
      <>
        <LoginBackButton plusFunc={plusFunc} />
        <MLogin />
      </>
    );
  };

  useEffect(() => {
    if (window.ReactNativeWebView) {
      window.ReactNativeWebView.postMessage(JSON.stringify(state));
    }
  }, [state]);

  useEffect(() => {
    const initializeLoginState = async () => {
      const storedLoginInfo = await loadLoginInfoFromAsyncStorage();
      if (storedLoginInfo) {
        // Redux 스토어 업데이트
        dispatch(allActions.setMLoginInfo(storedLoginInfo));
      }
    };

    initializeLoginState();
  }, [dispatch]);

  useEffect(() => {
    if (mLoginInfo) {
      if (mLoginInfo.pay_yn === "n") {
        setIsLogIn(false);
        // iOS 체크 후 라우트 설정
        if (isIOS) {
          setInitialRoute("/DeniedLogin");
          console.log("iOS device detected - routing to DeniedLogin");
        } else {
          setInitialRoute("/SABPay");
          console.log("Non-iOS device detected - routing to SABPay");
        }
      } else {
        setIsLogIn(true);
        setInitialRoute("/MLogin");
      }
    } else {
      setIsLogIn(false);
      setInitialRoute("/");
    }
  }, [mLoginInfo, isIOS]);

  useEffect(() => {
    window.addEventListener("message", handleMessage);
    return () => {
      window.removeEventListener("message", handleMessage);
    };
  }, []);

  const handleMessage = (event: any) => {
    if (event.data === "backButtonPressed") {
      window.ReactNativeWebView.postMessage("confirmBackPress");
    }
  };

  const BusinessWithBackButton = withBackButton({
    Component: BusinessHome,
    disableLogout: true,
  });
  const TrainingWithBackButton = withBackButton({
    Component: TrainerHome,
    disableLogout: true,
  });
  const LoginWithBackButton = withBackButton({
    Component: MLogin,
    disableLogout: true,
    showBackButton: false,
    showWhiteBackButton: true,
  });
  const JoinPageBWithBackButton = withBackButton({
    Component: JoinPageB,
    disableLogout: true,
    showBackButton: true,
  });
  const JoinPageTWithBackButton = withBackButton({
    Component: JoinPageT,
    disableLogout: true,
    showBackButton: true,
  });
  const TermsAgreeWithBackButton = withBackButton({
    Component: TermsAgree,
    disableLogout: true,
    showBackButton: true,
  });
  const RatePlanWithBackButton = withBackButton({
    Component: RatePlan,
    disableLogout: true,
    showBackButton: true,
  });
  const SBVideoPlayWithBackButton = withBackButton({
    Component: SBVideoPlay,
    disableLogout: true,
    showBackButton: true,
  });
  const VideoPlayPageWithBackButton = withBackButton({
    Component: VideoPlayerPage,
    disableLogout: true,
    showBackButton: true,
  });
  // const VideoPlayWithBackButton = withBackButton({Component: VideoPlayM, disableLogout: true, showBackButton: true});
  const VideoPlayWithBackButton = withBackButton({
    Component: VideoPlay,
    disableLogout: true,
    showWhiteBackButton: false,
  });
  const ProgramsItemWithBackButton = withBackButton({
    Component: ProgramsItemM,
    disableLogout: true,
    showBackButton: false,
  });
  const DayProgramWithBackButton = withBackButton({
    Component: CheckedDayProgram,
    isLogIn,
    disableLogout: true,
    showBackButton: false,
  });
  const DeninedLoginWithBackButton = withBackButton({
    Component: DeniedLogin,
    isLogIn,
    whiteLogout: false,
    disableLogout: true,
    showBackButton: false,
  });
  const ProgramsVideoMWithBackButton = withBackButton({
    Component: ProgramsVideoM,
    showBackButton: true,
  });
  const DayProgramTutorialMWithBackButton = withBackButton({
    Component: DayProgramTutorialM,
    disableLogout: true,
    showBackButton: false,
  });
  // const TimeSetupMWithBackButton = withBackButton({ Component: TimeSetupM, handleLogout });
  // const MVideoPlayWithBackButton = withBackButton({ Component: MVideoPlay, handleLogout });
  const AccountSetupWithBackButton = withBackButton({
    Component: CheckedAccountSetup,
  });
  const ProgramsVideoM2WithBackButton = withBackButton({
    Component: CheckedProgramsVideo,
    disableLogout: true,
    showBackButton: false,
  });
  const TimeSetupWithBackButton = withBackButton({
    Component: TimeSetupM,
    disableLogout: true,
    showBackButton: true,
  });
  const MusicSetupWithBackButton = withBackButton({
    Component: MusicSetupM,
    disableLogout: true,
    showBackButton: true,
  });
  const ForgotPasswordWithBackButton = withBackButton({
    Component: ForgotPassword,
    disableLogout: true,
    showBackButton: true,
  });
  const ForgotSuccessWithBackButton = withBackButton({
    Component: ForgotSuccess,
    disableLogout: true,
    showBackButton: true,
  });

  const ForgotMyAccountWithBackButton = withBackButton({
    Component: CheckedMyAccount,
    disableLogout: true,
    whiteLogout: true,
    showWhiteBackButton: true,
  });

  const SBPayWithBackButton = withBackButton({
    Component: SABPay,
    disableLogout: false,
    showBackButton: true,
  });

  const NumberPadWithBackButton = withBackButton({
    Component: NumberPad,
    disableLogout: true,
    whiteLogout: false,
    showWhiteBackButton: true,
  });

  return (
    <Router>
      <Switch>
        <Route path="/" exact>
          {/* {initialRoute === "/SABPay" ? <SBPayWithBackButton /> : isLogIn ? <DayProgramWithBackButton /> :
                        <Home />} */}
          {initialRoute === "/DeniedLogin" ? (
            <DeninedLoginWithBackButton />
          ) : initialRoute === "/SABPay" ? (
            <SBPayWithBackButton />
          ) : isLogIn ? (
            <MLoginWithCustomBack />
          ) : (
            <Home />
          )}
        </Route>
        <Route path="/MLogin" exact component={LoginWithBackButton} />
        <Route path="/BusinessHome" exact component={BusinessWithBackButton} />
        <Route path="/TrainerHome" exact component={TrainingWithBackButton} />
        <Route path="/JoinPageB" exact component={JoinPageBWithBackButton} />
        <Route path="/JoinPageT" exact component={JoinPageTWithBackButton} />
        <Route path="/TermsAgree" exact component={TermsAgreeWithBackButton} />
        <Route path="/RatePlan" exact component={RatePlanWithBackButton} />
        <Route path="/SignUpCompleteB" exact component={SignUpCompleteB} />
        <Route path="/SignUpCompleteT" exact component={SignUpCompleteT} />
        <Route
          path="/MyAccount"
          exact
          component={ForgotMyAccountWithBackButton}
        />
        <Route path="/MyAccountB" exact component={MyAccountB} />
        <Route path="/MyAccountB2" exact component={MyAccountB2} />
        <Route
          path="/SBVideoPlay"
          exact
          component={SBVideoPlayWithBackButton}
        />
        <Route path="/VideoPlay" exact component={VideoPlayWithBackButton} />
        <Route
          path="/VideoPlayPage"
          exact
          component={VideoPlayPageWithBackButton}
        />
        <Route
          path="/ProgramsItemM"
          exact
          component={ProgramsItemWithBackButton}
        />
        <Route
          path="/DayProgramM"
          exact
          render={(props) => <DayProgramWithBackButton {...props} />}
        />
        <Route
          path="/DeniedLogin"
          exact
          component={DeninedLoginWithBackButton}
        />
        <Route
          path="/ProgramsVideoM"
          exact
          component={ProgramsVideoMWithBackButton}
        />
        <Route
          path="/DayProgramTutorialM"
          exact
          component={DayProgramTutorialMWithBackButton}
        />
        <Route
          path="/AccountSetup"
          exact
          component={AccountSetupWithBackButton}
        />
        <Route
          path="/ProgramsVideoM2"
          exact
          component={ProgramsVideoM2WithBackButton}
        />
        <Route path="/TimeSetup" exact component={TimeSetupWithBackButton} />

        <Route path="/SABPayList" exact component={SABPayList} />
        <Route path="/SABPay" exact component={SBPayWithBackButton} />
        <Route path="/MusicSetup" exact component={MusicSetupWithBackButton} />
        <Route
          path="/ForgotPassword"
          exact
          component={ForgotPasswordWithBackButton}
        />
        <Route
          path="/ForgotSuccess"
          exact
          component={ForgotSuccessWithBackButton}
        />
        <Route path="/NumberPad" exact component={NumberPadWithBackButton} />
      </Switch>
    </Router>
  );
};

export default MobileRouter;

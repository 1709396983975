import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";
import React, { useState } from "react";
import * as ServerApi from "../../constants/ServerApiM";
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch, faUserCheck } from "@fortawesome/free-solid-svg-icons";
import Modal from "./CenterModal";
import AlertModal from "react-modal";
import { CenterInfo, JoinFormProps, FormData } from "../Interface/Interface";
import { useDispatch } from "react-redux";
import allActions from "../../component/redux/allActions";
import id from "../../img/id.png";
import name from "../../img/email.png";
import safari from "../../img/safari.png";
import phone from "../../img/phone.png";
import pw from "../../img/password.png";
import keyImg from "../../img/key.png";
import NewButtons from "./NewButtons";

const StyledModal = styled(AlertModal)`
  background-color: #dddddd;
  color: white;
  width: 80vw;
  height: 200px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 15px;
  padding-bottom: 10px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

const ModalText = styled.div`
  text-align: center;
  width: 100%;
  overflow-y: auto;
  font-size: 1rem;
  font-weight: 500;
  padding: 10px 0;
  margin-bottom: 10px;
  color: #000;
  line-height: 1.5rem;
`;

const ConfirmButton = styled.button`
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  background-color: #09348a;
  color: white;
  font-weight: 600;
  cursor: pointer;

  &:hover {
    background-color: #09348a;
  }
`;

const SearchButton = styled.button`
  position: absolute;
  right: 20px;
  top: 50%;
  transform: translateY(-50%);
  background: transparent;
  border: none;
  cursor: pointer;
  color: #eeeeee;

  &:disabled {
    cursor: default;
    opacity: 0.5;
  }
`;

const DuplicateCheckButton = styled.button`
  position: absolute;
  right: 20px;
  top: 50%;
  transform: translateY(-50%);
  background: transparent;
  color: #eeeeee;
  border: none;
  cursor: pointer;
`;

const SubmitContainer = styled.div`
  margin-top: 40px;
`;

const JoinFormContainer = styled.form`
  width: 80%;
  height: 100%;
`;

const JoinBox = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  align-items: center;
  background-color: rgba(49, 54, 84, 0.3);
  width: 100%;
  padding: 10px 15px;
  margin-top: 15px;
  border-radius: 10px;
  border: none;
  backdrop-filter: blur(6px);

  > img {
    height: 12px;
  }
`;

const JoinBoxStyle = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  align-items: center;
  margin: 10px auto 10px auto;
  background: transparent;

  input {
    background: transparent;
    border: none;
    color: #ffffff;
    margin-left: 20px;
    font-size: 16px;
  }

  img {
    width: 16px;
  }
`;

const Error = styled.div`
  position: absolute;
  bottom: -12%;
  left: 50%;
  transform: translate(-50%, 50%);
  color: #ffffff;
  background-color: rgba(49, 54, 84, 0.3);
  backdrop-filter: blur(6px);
`;

const JoinFormComponent: React.FC<JoinFormProps> = ({
  fieldsToShow,
  linksToShow,
}: JoinFormProps) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const {
    handleSubmit,
    control,
    getValues,
    setValue,
    formState: { errors, isValid },
  } = useForm<FormData>({
    mode: "onChange",
  });
  const [isDuplicateChecked, setIsDuplicateChecked] = useState(false);

  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [modalMessage, setModalMessage] = useState("");
  const [dnStatus, setDnStatus] = useState<
    "ready" | "loading" | "error" | "success"
  >("ready");

  const [showModal, setShowModal] = useState(false);
  const [selectedCenter, setSelectedCenter] = useState("");

  const openAlertModal = (message: any) => {
    setModalMessage(message);
    setModalIsOpen(true);
  };

  const closeAlertModal = () => {
    setModalIsOpen(false);
  };

  // 중복 검사 함수
  const handleDuplicateCheck = async () => {
    const userid = getValues("userid");

    // 이메일 양식 검사
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(userid)) {
      openAlertModal("유효하지 않은 이메일 양식입니다.");
      return;
    }

    if (!userid) {
      openAlertModal("아이디를 입력해주세요.");
      return;
    }
    try {
      const response = await ServerApi.m_app_check_id(userid);
      if (response.rsp_code === "200") {
        openAlertModal(
          "이미 사용중인 아이디입니다. 다른 아이디를 입력해주세요."
        );
        setIsDuplicateChecked(false);
      } else {
        openAlertModal("사용 가능한 아이디입니다.");
        setIsDuplicateChecked(true);
      }
    } catch (error) {
      console.error("중복 검사 중 오류 발생:", error);
      openAlertModal("중복 검사 중 문제가 발생했습니다.");
      setIsDuplicateChecked(false);
    }
  };

  const onSubmit: SubmitHandler<FormData> = async (data) => {
    const areAllFieldsFilled = Object.values(data).every(
      (value) => value !== ""
    );

    if (!areAllFieldsFilled) {
      setModalMessage("모든 폼에 데이터가 있어야 합니다.");
      setModalIsOpen(true);
      return;
    }

    console.log("폼데이터 제출", data);
    setDnStatus("loading");
    setTimeout(() => {
      if (!isDuplicateChecked) {
        setTimeout(() => {
          setDnStatus("error");
          setTimeout(() => {
            openAlertModal("아이디 중복 검사를 먼저 진행해주세요.");
            setDnStatus("ready");
          }, 1000);
        }, 1000);
        return;
      }
      // 불변성을 위해 data 를 새로운 객체로 생성하여 member_type 설정
      const updatedData = {
        ...data,
        member_type: linksToShow.includes("business") ? "m1" : "m2",
      };

      console.log("회원가입 데이터들", updatedData);

      // 리덕스에 회원가입 데이터 저장
      dispatch(allActions.setSignUpData(updatedData));

      // 약관 동의 페이지로 리디렉션
      if (linksToShow.includes("business")) {
        data.member_type = "m1";
        history.push("/TermsAgree", { member_type: "m1" });
      } else if (linksToShow.includes("trainer")) {
        data.member_type = "m2";
        history.push("/TermsAgree", { member_type: "m2" });
        setDnStatus("success");
      }
    }, 1000);
  };

  const openModal = () => {
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
  };

  const handleModalButtonClick = (center: CenterInfo) => {
    setSelectedCenter(center.center_nm);
    setValue("center_nm", center.center_nm);

    // 사업자 가입시 center_cd, 트레이너 가입시 center_no 데이터 전송
    if (linksToShow.includes("business")) {
      setValue("center_cd", center.center_cd);
    } else if (linksToShow.includes("trainer")) {
      setValue("center_no", center.center_no);
    }

    closeModal();
  };

  return (
    <>
      <JoinFormContainer onSubmit={handleSubmit(onSubmit)}>
        {fieldsToShow.includes("place") && (
          <JoinBox>
            <JoinBoxStyle>
              <img src={safari} alt="Place" />
              <Controller
                name="center_nm"
                control={control}
                defaultValue=""
                rules={{ required: "PLACE를 선택해주세요" }}
                render={({ field }) => (
                  <input
                    type="text"
                    id="place"
                    className="input"
                    placeholder="Place"
                    {...field}
                    value={selectedCenter || field.value}
                    readOnly
                  />
                )}
              />
              {errors.center_nm && <Error>{errors.center_nm.message}</Error>}
              <SearchButton type="button" onClick={openModal}>
                <FontAwesomeIcon icon={faSearch} />
              </SearchButton>
            </JoinBoxStyle>
          </JoinBox>
        )}
        {fieldsToShow.includes("newPlace") && (
          <JoinBox>
            <JoinBoxStyle>
              <img src={safari} alt="Place" />
              <Controller
                name="center_nm"
                control={control}
                defaultValue=""
                rules={{ required: "PLACE를 입력해주세요" }}
                render={({ field }) => (
                  <input
                    type="text"
                    id="place"
                    className="input"
                    placeholder="Place"
                    {...field}
                    value={selectedCenter || field.value}
                  />
                )}
              />
              {errors.center_nm && <Error>{errors.center_nm.message}</Error>}
            </JoinBoxStyle>
          </JoinBox>
        )}
        {fieldsToShow.includes("name") && (
          <JoinBox>
            <JoinBoxStyle>
              <img src={id} alt="ID" />
              <Controller
                name="name"
                control={control}
                defaultValue=""
                rules={{ required: "이름을 입력해주세요" }}
                render={({ field }) => (
                  <input
                    type="text"
                    id="name"
                    placeholder="User Name"
                    autoComplete="username"
                    {...field}
                  />
                )}
              />
              {errors.name && <Error>{errors.name.message}</Error>}
            </JoinBoxStyle>
          </JoinBox>
        )}

        {fieldsToShow.includes("email") && (
          <JoinBox>
            <JoinBoxStyle>
              <img src={name} alt="name" />
              <Controller
                name="userid"
                control={control}
                defaultValue=""
                rules={{
                  required: "이메일(ID)은 필수입니다.",
                  pattern: {
                    value: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
                    message: "유효하지 않은 이메일 양식입니다.",
                  },
                }}
                render={({ field }) => (
                  <input
                    type="email"
                    id="email"
                    className="input"
                    placeholder="ID - Email"
                    autoComplete="email"
                    {...field}
                  />
                )}
              />
              {errors.userid && <Error>{errors.userid.message}</Error>}
              <DuplicateCheckButton
                type="button"
                onClick={handleDuplicateCheck}
              >
                <FontAwesomeIcon icon={faUserCheck} />
              </DuplicateCheckButton>
            </JoinBoxStyle>
          </JoinBox>
        )}
        {fieldsToShow.includes("password") && (
          <JoinBox>
            <JoinBoxStyle>
              <img src={pw} alt="Password" />
              <Controller
                name="password"
                control={control}
                defaultValue=""
                rules={{ required: "패스워드를 입력해주세요", maxLength: 4 }}
                render={({ field }) => (
                  <input
                    type="password"
                    id="password"
                    className="input"
                    placeholder="Password"
                    autoComplete="current-password"
                    maxLength={4}
                    {...field}
                  />
                )}
              />
              {errors.password && <Error>{errors.password.message}</Error>}
            </JoinBoxStyle>
          </JoinBox>
        )}
        {fieldsToShow.includes("code") && (
          <JoinBox>
            <JoinBoxStyle>
              <img src={keyImg} alt="PlaceCode" />
              <Controller
                name="center_cd"
                control={control}
                defaultValue=""
                rules={{ required: "센터코드를 입력해주세요", maxLength: 4 }}
                render={({ field }) => (
                  <input
                    type="text"
                    id="code"
                    className="input"
                    placeholder="Place Code"
                    {...field}
                  />
                )}
              />
              {errors.center_cd && <Error>{errors.center_cd.message}</Error>}
            </JoinBoxStyle>
          </JoinBox>
        )}
        {fieldsToShow.includes("phone") && (
          <JoinBox>
            <JoinBoxStyle>
              <img src={phone} alt="Phone" />
              <Controller
                name="handphone"
                control={control}
                defaultValue=""
                rules={{ required: "전화번호를 입력해주세요" }}
                render={({ field }) => (
                  <input
                    type="tel"
                    id="phone"
                    className="input"
                    placeholder="Phone Number"
                    {...field}
                  />
                )}
              />
              {errors.handphone && <Error>{errors.handphone.message}</Error>}
            </JoinBoxStyle>
          </JoinBox>
        )}
        <SubmitContainer>
          <NewButtons
            text="Continue"
            dnStatus={dnStatus}
            myClick={handleSubmit(onSubmit)}
            backGround={"white"}
          />
        </SubmitContainer>
      </JoinFormContainer>
      {showModal && (
        <Modal onClose={closeModal} onButtonClick={handleModalButtonClick} />
      )}

      <StyledModal
        isOpen={modalIsOpen}
        onRequestClose={closeAlertModal}
        contentLabel="Alert Modal"
        style={{
          overlay: {
            backgroundColor: "rgba(0, 0, 0, 0.6)",
            zIndex: "99",
          },
        }}
      >
        <ModalText>{modalMessage}</ModalText>
        <ConfirmButton onClick={closeAlertModal}>Close</ConfirmButton>
      </StyledModal>
    </>
  );
};

export default JoinFormComponent;
